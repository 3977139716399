<script setup>
import {
  CUSTOM_FUNCTIONS,
  INPUT_TYPE_CUSTOM_FUNCTION,
  INPUT_TYPE_FIELD,
  INPUT_TYPE_OPERATOR,
  INPUT_TYPE_VALUE,
  OPERATORS,
} from '@component-library/business-model/expression';
import InfoButton from '@component-library/components/InfoButton.vue';
import { ref } from 'vue';
import CustomFunctionPicker from './CustomFunctionPicker.vue';
import FieldPicker from './FieldPicker.vue';
import OperatorPicker from './OperatorPicker.vue';
import ValueInput from './ValueInput.vue';

const props = defineProps({
  sections: {
    type: Array,
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['add']);

const currentInputType = ref(INPUT_TYPE_FIELD);

function checkIsActive(inputType) {
  return currentInputType.value === inputType;
}

function handleTabClick(inputType) {
  currentInputType.value = inputType;
}

function handleAdd(input) {
  emit('add', input);

  if ([INPUT_TYPE_FIELD, INPUT_TYPE_VALUE].includes(currentInputType.value)) {
    currentInputType.value = INPUT_TYPE_OPERATOR;
  } else if (currentInputType.value === INPUT_TYPE_CUSTOM_FUNCTION) {
    currentInputType.value = INPUT_TYPE_FIELD;
  } else {
    currentInputType.value = INPUT_TYPE_VALUE;
  }
}
</script>

<template>
  <div class="tabbed-inputs d-flex flex-column">
    <div class="tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            :class="[
              {
                active: checkIsActive(INPUT_TYPE_FIELD),
              },
              'nav-link',
            ]"
            :aria-current="checkIsActive(INPUT_TYPE_FIELD)"
            @click.prevent="handleTabClick(INPUT_TYPE_FIELD)"
          >
            Add a Field
            <InfoButton
              class="ms-2"
              info="Click the add button to add the field into
              the expression. Aggregation methods, e.g. MIN, MAX, Count etc,
              are available for fields from repetitive sections."
              container="div.tabbed-inputs"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="[
              {
                active: checkIsActive(INPUT_TYPE_OPERATOR),
              },
              'nav-link',
            ]"
            :aria-current="checkIsActive(INPUT_TYPE_OPERATOR)"
            @click.prevent="handleTabClick(INPUT_TYPE_OPERATOR)"
            >Add an Operator
            <InfoButton
              class="ms-2"
              info="Click an operator button below to add it into the expression."
              container="div.tabbed-inputs"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="[
              {
                active: checkIsActive(INPUT_TYPE_VALUE),
              },
              'nav-link',
            ]"
            :aria-current="checkIsActive(INPUT_TYPE_VALUE)"
            @click.prevent="handleTabClick(INPUT_TYPE_VALUE)"
            >Add a Value</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="[
              {
                active: checkIsActive(INPUT_TYPE_CUSTOM_FUNCTION),
              },
              'nav-link',
            ]"
            :aria-current="checkIsActive(INPUT_TYPE_CUSTOM_FUNCTION)"
            @click.prevent="handleTabClick(INPUT_TYPE_CUSTOM_FUNCTION)"
            >Add a Custom Function
          </a>
        </li>
      </ul>
    </div>

    <div
      class="flex-grow-1 tab-content"
      style="overflow-y: auto; min-height: 0px"
    >
      <FieldPicker
        v-if="checkIsActive(INPUT_TYPE_FIELD)"
        class="h-100"
        :fields="fields"
        :sections="sections"
        @add="handleAdd"
      />
      <OperatorPicker
        v-if="checkIsActive(INPUT_TYPE_OPERATOR)"
        class="h-100"
        :operators="OPERATORS"
        @add="handleAdd"
      />
      <ValueInput
        v-if="checkIsActive(INPUT_TYPE_VALUE)"
        class="h-100"
        @add="handleAdd"
      />
      <CustomFunctionPicker
        v-if="checkIsActive(INPUT_TYPE_CUSTOM_FUNCTION)"
        class="h-100"
        :customFunctions="CUSTOM_FUNCTIONS"
        @add="handleAdd"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$border_color: #dee2e6;

.tabbed-inputs {
  .tabs {
    .nav-link {
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
    }
  }

  .tab-content {
    border-left: 1px solid $border_color;
    border-right: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    padding: 10px;
  }
}
</style>
