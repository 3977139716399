<script lang="ts" setup>
import Modal from '../../components/Modal.vue';
import ButtonSpinner from '../../components/ButtonSpinner.vue';
import Table from '../../components/Table.vue';
import { useProjectStore } from '../../store/project';
import { useToastStore } from '../../store/toasts';
import { onBeforeMount, ref } from 'vue';
import { TabLogProject } from '../tab-logs';
import { useDialogStore } from '../../store/dialog';
import AlertBox from '../../components/AlertBox.vue';
import useApi from '../../api';

const projectStore = useProjectStore();
const toasts = useToastStore();
const lastTabLogsPage = ref(0);
const canLoadMoreTabLogsProjects = ref(true);
const canBrowseProjects = ref(true);
const tabLogProjects = ref<TabLogProject[]>([]);
const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'matched', tabLogsProjectNumber: string): void;
}>();
const api = useApi();

const isLoadingProjects = ref(false);
async function loadTabLogsItems(page: number) {
  if (isLoadingProjects.value) {
    console.error('Already loading projects');
    return;
  }

  try {
    const response = await api.get(
      '/projects/' +
        projectStore.currentProject!.project_id +
        '/tab-logs/projects',
      {
        params: { page, project_id: projectStore.currentProject!.project_id },
      }
    );

    canBrowseProjects.value = response.data.can_browse_projects;
    canLoadMoreTabLogsProjects.value =
      response.data.tab_logs_projects.length >= response.data.page_size;

    lastTabLogsPage.value = page;
    if (page === 1) {
      tabLogProjects.value = [];
    }
    for (let project of response.data.tab_logs_projects) {
      tabLogProjects.value.push(project);
    }
  } catch (e: any) {
    toasts.unexpected(e);
    throw e;
  } finally {
    isLoadingProjects.value = false;
  }
}

function loadMoreTabLogsProjects() {
  loadTabLogsItems(lastTabLogsPage.value + 1);
}

function createNewTabLogsProject() {
  api
    .post(
      '/projects/' +
        projectStore.currentProject!.project_id +
        '/tab-logs/projects',
      {
        project_id: projectStore.currentProject!.project_id,
      }
    )
    .then(
      (response) => {
        projectStore.currentProject!.tab_logs_project_number =
          response.data.tab_logs_project_number;
        projectStore.updateProject(projectStore.currentProject!);
        toasts.success('TabLogs project created');
        emit('matched', response.data.tab_logs_project_number);
      },
      (error) => {
        if (error?.response?.data?.tab_logs_project_number) {
          projectStore.currentProject!.tab_logs_project_number =
            error.response.data.tab_logs_project_number;
          projectStore.updateProject(projectStore.currentProject!);
          toasts.warning('TabLogs project already matched');
          emit('close');
          return;
        }
        toasts.error('Failed to create TabLogs project');
        throw error;
      }
    );
}

function linkTabLogsProject(tabLogProject: TabLogProject) {
  useDialogStore().confirm(
    'Link TabLogs Project',
    `Are you sure you wish to link Datanest project number: ${projectStore.currentProject?.project_number} to TabLogs project number: ${tabLogProject.project_number}?`,
    () => {
      if (!projectStore.currentProject?.project_id) {
        throw new Error('No current project');
      }
      api
        .post(
          '/projects/' +
            projectStore.currentProject.project_id +
            '/tab-logs/link',
          {
            tab_logs_project_number: tabLogProject.project_number,
          }
        )
        .then(
          (response) => {
            projectStore.currentProject!.tab_logs_project_number =
              tabLogProject.project_number;
            projectStore.updateProject(projectStore.currentProject!);
            toasts.success('TabLogs project linked');
            window.location.reload();
            emit('close');
          },
          (error) => {
            toasts.error('Failed to link TabLogs project');
            throw error;
          }
        );
    }
  );
}

onBeforeMount(() => {
  loadTabLogsItems(1);
});
</script>

<template>
  <Modal half :loading="isLoadingProjects" @close="emit('close')">
    <template #header>
      Choose a TabLogs Project to send Datanest logs to
    </template>

    <p
      v-if="!projectStore.currentProject?.tab_logs_project_number"
      class="text-muted"
    >
      To get started with Tab Logs we need to create a new project in Tab Logs
      or find an existing project to send data to.
    </p>

    <ButtonSpinner
      class="mb-3 btn btn-primary btn-lg"
      @click="createNewTabLogsProject"
    >
      Create new TabLogs project "{{
        projectStore.currentProject?.project_number
      }}"
    </ButtonSpinner>
    <div v-for="project of tabLogProjects" :key="project.id" class="row">
      <div class="col-12 col-lg-6">
        <div class="text-muted"></div>
      </div>
    </div>

    <template v-if="canBrowseProjects">
      <h6>Tab Logs projects:</h6>
      <Table v-if="tabLogProjects.length">
        <template #headingRaw>
          <th>Project Number</th>
          <th style="min-width: 200px">Project Name</th>
          <th>Client Name</th>
        </template>
        <tr
          v-for="tabLogProject of tabLogProjects"
          :key="tabLogProject.id"
          class="clickable"
          @click="linkTabLogsProject(tabLogProject)"
        >
          <td>{{ tabLogProject.project_number }}</td>
          <td>{{ tabLogProject.project_name }}</td>
          <td>{{ tabLogProject.client_name }}</td>
        </tr>
      </Table>
      <p v-else class="text-muted">No existing Tab Logs projects found.</p>
    </template>
    <AlertBox v-else type="info" class="mb-3">
      Please contact support about a direct TabLogs integration to connect your
      existing TabLogs projects.
    </AlertBox>

    <ButtonSpinner
      v-if="canLoadMoreTabLogsProjects && lastTabLogsPage > 0"
      class="mb-3 btn btn-sm btn-outline-secondary"
      :isLoading="isLoadingProjects"
      @click="loadMoreTabLogsProjects"
    >
      Load more
    </ButtonSpinner>
  </Modal>
</template>
