<template>
  <div
    v-if="loaded"
    :id="`category-dropdown`"
    v-click-outside="hideMenu"
    class="form-group"
  >
    <label class="form-label" for="dropdown"> Select category to add </label>

    <div class="input-group">
      <input
        ref="dropdownInput"
        type="text"
        class="form-control bg-white cursor-pointer"
        name="dropdown-replace"
        autocomplete="off"
        @focus="removeFocus"
        @click.prevent="toggleMenu"
      />
      <button
        class="btn btn-danger"
        type="button"
        @click.prevent="setValue(null)"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div v-if="isMenuVisible" class="dropdown-content">
      <input
        ref="searchOptions"
        v-model="search"
        type="text"
        class="form-control border-0"
        placeholder="Search options..."
        @input="searchOptions"
        @keyup.enter="searchOptions"
      />
      <a
        v-for="(option, optionIndex) in searchedOptions"
        :key="optionIndex"
        href="#"
        @click.prevent="setValue(option)"
        >{{ option.name }}</a
      >
      <a v-if="searchedOptions && !searchedOptions.length" class="text-muted">
        No options for "{{ search }}"
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    search: null,
    searchedOptions: [],
    isMenuVisible: false,
    loaded: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.searchedOptions = [...this.categories];
      this.loaded = true;
    });
  },
  methods: {
    removeFocus() {
      this.$refs.dropdownInput.blur();
    },
    async toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) {
        await this.$nextTick();
        this.$refs.searchOptions?.focus();
      }
    },
    hideMenu() {
      this.isMenuVisible = false;
    },
    setValue(value) {
      this.$emit('add', value);
      this.hideMenu();
      this.search = null;
      this.searchOptions();
    },
    searchOptions() {
      let options = this.categories;
      if (this.search) {
        this.searchedOptions = options.filter((aOption) =>
          aOption?.name?.toLowerCase().includes(this.search?.toLowerCase())
        );
      } else {
        this.searchedOptions = options;
      }
    },
  },
};
</script>

<style scoped>
/* Dropdown Content*/
.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 25rem;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #000 !important;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  color: #000 !important;
}
</style>
