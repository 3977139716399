import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedGeneric,
  RouteLocationRaw,
} from 'vue-router';
import store from './store';
import ProjectLayout from '@/js/layouts/Project.vue';
import FullScreenLayout from '@/js/layouts/FullScreen.vue';
import NotFound from '@/js/pages/Error404Page.vue';
import ListModule from '@/js/modules/project/list/index.vue';
import MapModule from '@/js/modules/project/map/index.vue';
import TemplateEditorModule from '@/js/pages/TemplateEditorPage.vue';
import ChainOfCustodyModule from '@/js/modules/project/chain-of-custody/index.vue';
import VersionControlModule from '@/js/modules/project/version-control/index.vue';
import AppManagerModule from '@/js/modules/project/app-manager/index.vue';
import HandoverModule from '@/js/pages/Handover.vue';
import { DATANEST_URL } from '@component-library/env';
import { useProjectStore } from '@component-library/store/project';
import { auth } from '@component-library/auth';
import { useNavigationStore } from '@component-library/store/navigation';
import axios from 'axios';

const DATANEST_PROJECTS_URL = DATANEST_URL + '/projects';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      redirect: '/projects',
    },
    {
      path: '/login',
      redirect: DATANEST_PROJECTS_URL,
    },
    {
      path: '/:project_id/map',
      component: FullScreenLayout,
      meta: {
        auth: true,
        projectRequired: true,
      },
      children: [
        {
          path: '',
          name: 'project_map',
          component: MapModule,
          meta: {
            title: 'Map',
          },
        },
      ],
    },
    {
      path: '/project',
      component: ProjectLayout,
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/projects',
          name: 'list',
          component: ListModule,
          meta: {
            title: 'Projects',
          },
        },
      ],
    },
    {
      path: '/template/:project_id/editor/:tab_id?',
      component: TemplateEditorModule,
      name: 'template_editor',
      meta: {
        title: 'App Editor',
        auth: true,
      },
    },
    {
      path: '/template/:project_id/apps',
      component: AppManagerModule,
      name: 'app_manager',
      meta: {
        title: 'App Manager',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/:project_id/chain-of-custody',
      component: ChainOfCustodyModule,
      name: 'coc_editor',
      meta: {
        title: 'Chain of Custody',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/:project_id/version-control/:sample_id',
      component: VersionControlModule,
      meta: {
        title: 'Version Control',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/handover/:access_token/:page?',
      component: HandoverModule,
    },
    {
      path: '/404',
      component: NotFound,
      meta: {
        title: 'Page Not Found',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ],
});

async function waitForStorageToBeReady(
  to: RouteLocationNormalizedGeneric
): Promise<RouteLocationRaw | boolean> {
  // @ts-expect-error - vuex persistence plugin
  await store.restored;

  const projectStore = useProjectStore();
  const toRouteMeta = to.matched[0].meta;

  if (to.name == 'list' && navigator.onLine) {
    if (!auth.check()) {
      window.location.href = DATANEST_PROJECTS_URL;
      throw 'User is not authenticated';
    }

    try {
      const { data } = await axios.get('/api/handover/datanest');
      window.location.href = data.redirect_url + '/project_pathway';
      return false;
    } catch (err: any) {
      if (err?.response?.status == 401) {
        window.location.href = DATANEST_PROJECTS_URL;
      }
      throw err;
    }
  }

  if (!toRouteMeta.projectRequired) {
    return true;
  }

  const project = projectStore.currentProject;
  const projectId = to.params.project_id;
  if (project && String(project.project_id) === projectId) {
    return true;
  }

  if (!projectId) {
    console.error('Project required, no project in state or route params.');
    projectStore.resetProject();
    return { path: '/projects' };
  }
  try {
    console.info('Project required, loading project from route param.');
    const { data } = await axios.get('/api/project/details', {
      params: {
        project_id: projectId,
      },
    });

    if (
      projectStore.currentProject &&
      projectStore.currentProject.project_id === data.project.id
    ) {
      projectStore.resetProject();
    }

    projectStore.updateProject(data.project);
    return true;
  } catch (e) {
    console.warn(
      'Failed to load project from store, redirecting to project list.'
    );
    console.error(e);

    projectStore.resetProject();

    return { path: '/projects' };
  }
}

router.beforeEach(async (to, _, next) => {
  const navigationStore = useNavigationStore();
  navigationStore.setLoadingLocalState(true);
  const n = await waitForStorageToBeReady(to);
  if (typeof n === 'boolean') {
    next();
  } else {
    next(n);
  }
});

router.afterEach(() => {
  const navigationStore = useNavigationStore();
  if (navigationStore.loadingLocalState) {
    navigationStore.setLoadingLocalState(false);
  }
});

export default router;
