<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';

import AlertBox from '@component-library/components/AlertBox.vue';
import InputRange from '@component-library/components/InputRange.vue';
import InputSelect from '@component-library/components/InputSelect.vue';
import InputTextarea from '@component-library/components/InputTextarea.vue';
import Upsell from '@component-library/components/Upsell.vue';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import { FieldTypeIds } from '@component-library/fields';
import {
  App,
  GatherAIFieldOptions,
  GatherField,
  RepeatModeOrAll,
} from '@component-library/gather';

const emit = defineEmits<{
  (event: 'updateField', payload: GatherField<GatherAIFieldOptions>): void;
}>();

const featureEnabled = computed(() => {
  return hasAccess(FEATURES.AI_GATHER_PROMPTS);
});

const props = defineProps<{
  value: GatherField<GatherAIFieldOptions>;
  tab: App;
}>();

const addInput = ref<number>();
const options = reactive(props.value.options ?? {});

let updateIndex = 0;
function updateOptions() {
  let index = updateIndex++;
  setTimeout(() => {
    if (index === updateIndex - 1) {
      emit('updateField', {
        ...props.value,
        options,
      });
    }
  }, 500);
}

watch(
  options,
  () => {
    updateOptions();
  },
  { deep: true }
);

onBeforeMount(() => {
  options.max_tokens ||= 500;
  options.temperature ||= 0.9;
});

function getField(id: number): GatherField | undefined {
  for (let s of props.tab.sections ?? []) {
    const match = s.template_fields?.find((field) => field.id === id);
    if (match) {
      return match;
    }
  }

  return undefined;
}

const ALLOWED_FILED_TYPES = [
  FieldTypeIds.NUMBER,
  FieldTypeIds.TEXT,
  FieldTypeIds.DROPDOWN,
  FieldTypeIds.DATE,
  FieldTypeIds.ADDRESS,
  FieldTypeIds.EXPRESSION,
  FieldTypeIds.USER,
  FieldTypeIds.CHECKBOX,
  FieldTypeIds.DEPTH,
  FieldTypeIds.AI_PROMPT,
] as readonly number[];

const fields = computed(() => {
  const currentSectionFields =
    props.tab?.sections?.find((s) => s.id === props.value.template_section_id)
      ?.template_fields ?? [];

  const otherNonRepeatingFields =
    props.tab.sections
      ?.filter(
        (s) => s.id !== props.value.template_section_id && !s.is_repeatable
      )
      .flatMap((s) => s.template_fields ?? []) ?? [];
  return [...currentSectionFields, ...otherNonRepeatingFields].filter(
    (f) =>
      ALLOWED_FILED_TYPES.includes(f.field_type_id) && f.id !== props.value.id
  );
});

function getSection(id: number) {
  return props.tab.sections?.find((s) => s.id === id);
}

watch(addInput, (value) => {
  if (value) {
    options.inputs ||= [];
    addInput.value = undefined;
    const field = getField(value);
    if (!field) {
      console.error('Field not found', value);
      return;
    }
    if (props.value.template_section_id !== field.template_section_id) {
      const section = getSection(field.template_section_id);
      if (!section) {
        console.error('Section not found', field.template_section_id);
        return;
      }
      if (section.is_repeatable) {
        console.error('Field not in current section', value);
        return;
      }
    }
    options.inputs.push([value, RepeatModeOrAll.Current]);
  }
});

function hasInput(id: number): boolean {
  return options.inputs?.some((input) => input[0] === id) ?? false;
}

function removeInput(input: [number, RepeatModeOrAll]) {
  options.inputs = options.inputs?.filter((i) => i !== input);
}

onMounted(() => {
  console.log('hasAccess', featureEnabled.value);
});
</script>

<template>
  <div>
    <Upsell :featureKey="FEATURES.AI_GATHER_PROMPTS">
      <InputTextarea
        v-model="options.system_prompt"
        label="System Prompt"
        placeholder='Enter an AI prompt, for example, "Summarize the main points of the text."'
        class="mb-3"
        :disabled="!featureEnabled"
      />

      <span
        v-if="options.inputs && options.inputs.length > 0"
        class="text-muted mb-3"
      >
        Input fields provided to the AI model:
        <div v-for="input of options.inputs">
          <span class="badge bg-primary me-1">{{
            getField(input[0])?.label ?? '(deleted)'
          }}</span>
          <i
            class="fas fa-trash clickable text-danger"
            @click="removeInput(input)"
          ></i>
        </div>
      </span>

      <AlertBox
        v-if="options.inputs && options.inputs.length >= 10"
        type="warning"
      >
        You have reached the maximum number of input fields.
      </AlertBox>
      <InputSelect
        v-else-if="fields.length > 0"
        v-model="addInput"
        label="Add Input Field"
        class="mb-3"
        :disabled="!featureEnabled"
      >
        <option :value="null">Choose input to send AI</option>
        <option
          v-for="field in fields"
          :value="field.id"
          :disabled="hasInput(field.id)"
        >
          {{ field.label }} in
          {{ getSection(field.template_section_id)?.label }}
        </option>
      </InputSelect>

      <InputRange
        v-model="options.max_tokens"
        :label="`Max response length ${options.max_tokens} tokens`"
        :min="100"
        :max="2000"
        :disabled="!featureEnabled"
        :step="10"
      />
      <p class="mb-3 text-muted">
        This is the number of tokens the AI model will respond with, this
        approximately corresponds to the number of words and punctuation in the
        response. Some words are multiple tokens.
      </p>

      <InputRange
        v-model="options.temperature"
        :label="`Temperature ${options.temperature}`"
        :min="0"
        :max="1"
        :step="0.1"
        :disabled="!featureEnabled"
      />
      <p class="mb-3 text-muted">
        This is the randomness of the AI model, a higher temperature will make
        the AI model more creative but less accurate.
      </p>
    </Upsell>
  </div>
</template>
