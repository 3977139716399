<script lang="ts" setup>
import { DateType } from '@component-library/business-model/date';
import { GatherDateOptions, GatherField } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import { computed, watch, onMounted, ref } from 'vue';

const props = defineProps<{
  value: GatherField<GatherDateOptions>;
}>();
const shouldUseCurrentDateTimeCheckboxId = ref(makeId());

const emit = defineEmits(['updateField']);

onMounted(defaultOptions);

const field = computed({
  get() {
    return props.value;
  },
  set(updated) {
    emit('updateField', updated);
  },
});
watch(field, defaultOptions);

const type = computed({
  get() {
    return props.value?.options?.type || null;
  },
  set(updated) {
    const options = { ...props.value?.options };
    if (updated) {
      options.type = updated;
    } else {
      delete options.type;
    }

    if (options.type !== 'date') {
      if (options.format === 'MM-YYYY') {
        options.format = 'DD-MM-YYYY';
      }
      if (options.format === 'YYYY') {
        options.format = 'DD-MM-YYYY';
      }
    }
    emit('updateField', { ...props.value, options });
  },
});

const format = computed({
  get() {
    return props.value?.options?.format;
  },
  set(updated) {
    const options = { ...(props.value?.options || { format: null }) };
    options.format = updated;
    if (!options.format) {
      delete options.format;
    }
    emit('updateField', { ...props.value, options });
  },
});

const current = computed({
  get() {
    return field.value.options?.current ?? true;
  },
  set(value) {
    const options = { ...field.value.options, current: value };
    emit('updateField', { ...props.value, options });
  },
});

function defaultOptions() {
  if (!type.value) {
    type.value = DateType.Datetime;
  }
}

function updateType(newType) {
  type.value = newType;
}

function updateFormat(newFormat: Event) {
  format.value = (newFormat.target as HTMLInputElement)
    .value as GatherDateOptions['format'];
}
</script>

<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Type</label>
      <div>
        <div class="btn-group btn-group-toggle w-100 mb-2">
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options?.type === 'date',
            }"
            @click="updateType('date')"
          >
            Date
          </button>
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options?.type === 'datetime',
            }"
            @click="updateType('datetime')"
          >
            Date & Time
          </button>
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options?.type == 'time',
            }"
            @click="updateType('time')"
          >
            Time Only
          </button>
        </div>
      </div>

      <template
        v-if="
          field.options?.type === 'date' || field.options?.type === 'datetime'
        "
      >
        <label>Date Format</label>
        <select
          class="form-control mb-2"
          :value="field.options.format ?? 'DD-MM-YYYY'"
          @input="updateFormat"
        >
          <option value="DD-MM-YYYY">DD-MM-YYYY</option>
          <option value="MM-DD-YYYY">MM-DD-YYYY</option>
          <option value="YYYY-MM-DD">YYYY-MM-DD</option>
          <template v-if="field.options.type === 'date'">
            <option value="MM-YYYY">MM-YYYY</option>
            <option value="YYYY">YYYY</option>
          </template>
        </select>
      </template>

      <div class="form-check">
        <input
          :id="shouldUseCurrentDateTimeCheckboxId"
          v-model="current"
          class="form-check-input"
          type="checkbox"
        />
        <label
          class="form-check-label"
          :for="shouldUseCurrentDateTimeCheckboxId"
        >
          Use the current date and time as the default value
        </label>
      </div>
    </div>
  </div>
</template>
