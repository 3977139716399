import { useStore } from '@/js/store';
import type { GeolocationResult } from '@maps/lib/olbm/types';
import type { Coordinate } from 'ol/coordinate';
import { equals as ol_coordinate_equals } from 'ol/coordinate';

const store = useStore();

export default class TrackingActivity {
  private lastVertexTimestamp: number | undefined = undefined;
  private geolocationChangeListener: (value: GeolocationResult) => void;

  constructor(public viewer) {
    this.geolocationChangeListener = this.handleGeolocationChange.bind(this);
  }

  getMap() {
    return this.viewer.getMap();
  }
  getDraw() {
    return this.getMap().getInteractionManager().getDraw();
  }
  start() {
    if (this.viewer.isTracking) {
      return;
    }

    this.viewer.isTracking = true;

    const map = this.getMap();
    map.$on('geolocationChange', this.geolocationChangeListener);
  }
  stop() {
    if (!this.viewer.isTracking) {
      return;
    }

    this.viewer.isTracking = false;
    this.lastVertexTimestamp = undefined;

    const map = this.getMap();
    map.$off('geolocationChange', this.geolocationChangeListener);
  }
  private checkShouldBeSkipped(position: Coordinate): boolean {
    const timestamp = Date.now();
    const draw = this.getDraw();
    const lastPosition = draw.sketchCoords_?.length
      ? draw.sketchCoords_[draw.sketchCoords_.length - 1]
      : undefined;

    if (!lastPosition) {
      return false;
    }

    if (ol_coordinate_equals(lastPosition, position)) {
      return true;
    }

    const { vertexCreationInterval } = store.state.persistence;

    return (
      !!this.lastVertexTimestamp &&
      timestamp - this.lastVertexTimestamp < vertexCreationInterval * 1000
    );
  }
  private handleGeolocationChange(value: GeolocationResult) {
    const timestamp = Date.now();
    const { position } = value;

    if (this.checkShouldBeSkipped(position)) {
      return;
    }

    const map = this.getMap();
    const draw = this.getDraw();

    draw.appendCoordinates([position]);
    map.animateView(position);
    this.lastVertexTimestamp = timestamp;
  }
}
