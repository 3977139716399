export function sortFigures(figures) {
  const splitFigureNo = (figureNo) => figureNo.split('.');
  const normalizeValue = (v) => {
    const parsedV = parseInt(v, 10);
    return !Number.isNaN(parsedV) ? parsedV : v ?? 0;
  };
  const basemapFigureIndex = figures.findIndex((sf) => sf.is_basemap);
  const hasBasemapFigure = basemapFigureIndex !== -1;

  let sortingFigures = [...figures];
  if (hasBasemapFigure) {
    sortingFigures.splice(basemapFigureIndex, 1);
  }
  const splittedFigureNos = sortingFigures.map((f) =>
    splitFigureNo(f.figure_no)
  );
  const max = Math.max(...splittedFigureNos.map((sfno) => sfno.length));
  const sortedFigures = sortingFigures.sort((sf1, sf2) => {
    const splittedFigureNo1 = splitFigureNo(sf1.figure_no);
    const splittedFigureNo2 = splitFigureNo(sf2.figure_no);

    for (let i = 0; i < max; i++) {
      const value1 = normalizeValue(splittedFigureNo1[i]);
      const value2 = normalizeValue(splittedFigureNo2[i]);

      let result;
      if (typeof value1 === 'number' && typeof value2 === 'number') {
        result = value1 - value2;
      } else {
        result = String(value1).localeCompare(String(value2));
      }
      if (result !== 0) {
        return result;
      }
    }

    return sf1.title.localeCompare(sf2.title);
  });

  return hasBasemapFigure
    ? [figures[basemapFigureIndex], ...sortedFigures]
    : sortedFigures;
}

export function getFigureTitle(figure) {
  const { is_basemap, figure_no, title } = figure;
  return !is_basemap ? `${figure_no}. ${title}` : title;
}
