<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  field: {
    options: {
      has_multiple: boolean;
      track_direction?: boolean;
      type?: string;
    };
  };
  value: { value: any; options?: { direction?: string } };
  values: Array<{ name?: string }>;
}>();

const emit = defineEmits<{
  (event: 'viewImage', imageUrl: string | string[]): void;
  (event: 'viewMedia', media: { src: any; type?: string }): void;
}>();

const imageUrl = computed(() => {
  const { has_multiple } = props.field.options;
  let { value: url } = props.value;
  if (Array.isArray(url) && url.length && !has_multiple) {
    url = url.slice(0, 1);
  }
  return url;
});

const hasImageDirection = computed(() => {
  const { has_multiple, track_direction } = props.field.options;
  return !has_multiple && track_direction;
});

const imageDirection = computed(() => {
  return hasImageDirection.value ? props.value.options?.direction ?? 'N' : null;
});

function viewImage() {
  emit('viewImage', imageUrl.value);
}

function viewMedia() {
  emit('viewMedia', {
    src: props.value.value,
    type: props.field.options.type,
  });
}
</script>

<template>
  <div>
    <div v-if="field.options.type == 'image' || !field.options.type">
      <span
        v-if="hasImageDirection"
        class="badge bg-primary w-auto ms-2"
        title="Image direction"
      >
        {{ imageDirection }}
      </span>
      <a href="#" class="fw-medium" @click.prevent="viewImage">
        View
        {{
          imageUrl && Array.isArray(imageUrl) && imageUrl.length > 1
            ? 'Images'
            : values.length === 1 && values[0].name
            ? values[0].name
            : 'Image'
        }}
      </a>
    </div>
    <a
      v-if="field.options.type == 'audio'"
      href="#"
      class="fw-medium"
      @click.prevent="viewMedia"
    >
      Listen
    </a>
    <a
      v-if="field.options.type == 'video'"
      href="#"
      class="fw-medium"
      @click.prevent="viewMedia"
    >
      Watch
    </a>
  </div>
</template>
