<script lang="ts" setup>
import { computed, watch } from 'vue';
import { getFileExtension } from '@component-library/utils';
import useFileUpload from '@component-library/composables/useFileUpload';
import FileUploader from '@component-library/components/FileUploader.vue';
import { useRoute } from 'vue-router';
import { SUPPORTED_TYPES } from '@component-library/business-model/document';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['updateOptions']);

const { project_id: projectId } = useRoute().params;

const defaultValue = computed({
  get() {
    return props.field.options.default;
  },
  set(value) {
    emit('updateOptions', {
      ...props.field.options,
      default: value,
    });
  },
});

const fileExtension = computed(() => {
  return defaultValue ? getFileExtension(defaultValue.value.fileName) : null;
});

const fileIcon = computed(() => {
  return (
    {
      pdf: 'fas fa-file-pdf fa-3x',
      doc: 'fas fa-file-word fa-3x',
      docx: 'fas fa-file-word fa-3x',
      csv: 'fas fa-file-csv fa-3x',
      ods: 'fas fa-file-spreadsheet fa-3x',
      odt: 'fas fa-file-alt fa-3x',
      txt: 'fas fa-file-alt fa-3x',
      xlx: 'fas fa-file-spreadsheet fa-3x',
      xlsx: 'fas fa-file-spreadsheet fa-3x',
    }[fileExtension.value] ?? 'fas fa-file-alt fa-3x'
  );
});

const fileUrl = computed(() => {
  const { assetName } = defaultValue.value;
  return `/api/images/value/${projectId}/${assetName}`;
});

const {
  result,
  isUploading,
  uploadingProgress,
  isUploadingComplete,
  uploadFile,
} = useFileUpload(projectId);

watch(result, () => {
  defaultValue.value = result.value ? { ...result.value } : null;
});

async function handleFileUpload(file) {
  uploadFile(file);
}

function handleFileRemove() {
  defaultValue.value = null;
}
</script>

<template>
  <div>
    <label> Default Value </label>
    <FileUploader
      v-if="!defaultValue"
      :supportedType="SUPPORTED_TYPES"
      :pending="isUploading"
      :progress="uploadingProgress"
      :complete="isUploadingComplete"
      @uploader="handleFileUpload"
    />
    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center dashed-border mt-3 pb-3"
    >
      <a
        :href="fileUrl"
        :target="fileExtension === 'pdf' ? '_blank' : '_self'"
        :download="fileExtension !== 'pdf' ? defaultValue.fileName : null"
      >
        {{ defaultValue.fileName }}
      </a>

      <div class="my-3">
        <i :class="`${fileIcon} text-primary`"></i>
      </div>

      <button
        type="button"
        class="btn btn-outline-danger mx-2"
        @click.prevent="handleFileRemove"
      >
        Remove File
      </button>
    </div>
  </div>
</template>
