<template>
  <div>
    <div class="mb-3 overlay-container">
      <div
        class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-3"
      >
        <h6 class="mb-0">App Settings</h6>
        <a
          href="#"
          class="text-dark p-1"
          @click.prevent="$emit('finishEditing')"
        >
          <i class="fas fa-times"></i>
        </a>
      </div>

      <label class="form-label" :class="{ 'text-danger': invalidNameError }">
        <InfoButton
          v-if="invalidNameError"
          class="me-2"
          :danger="true"
          backgroundColor="#FF0000"
          :info="invalidNameError"
        />
        App Name
      </label>
      <input
        ref="appTitle"
        v-model.trim="appTitle"
        v-tooltip="invalidNameError"
        class="form-control mb-3"
        data-cy="data-type-name"
        :class="{ 'text-danger is-invalid': invalidNameError }"
        placeholder="Name your Gather App"
        @keyup.enter="updateAppTitle"
        @blur="updateAppTitle"
      />
      <label class="form-label">Default prefix for collected items</label>
      <input
        ref="tabPrefix"
        v-model="tabPrefix"
        class="form-control mb-1"
        data-cy="data-type-name-prefix"
        placeholder="Set default prefix for data collection items"
        :disabled="!!tab.item_title_field_id"
        @keyup.enter="updateTabPrefix"
        @blur="updateTabPrefix"
      />

      <InputCheckbox
        v-if="tabPrefix && tabPrefix.length > 0"
        v-model="hasReadOnlyItemTitles"
        label="Read-only item title"
        class="mb-3"
      />

      <UseFieldAsItemTitle
        v-if="availableFieldsForItemTitle.length > 0"
        class="mb-3"
        :tabId="tab.id"
        :itemTitleFieldId="tab.item_title_field_id"
        :fields="availableFieldsForItemTitle"
        @change="handleUseFieldAsTitleChange"
      />
      <div class="overlay-container">
        <div v-if="tabIsLocked" class="lock-overlay">
          <i class="fa fa-lock icon-overlay"></i>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Collection Type</label>
          <div>
            <div
              class="btn-group btn-group-sm w-100 flex-wrap gap-1 align-items-start"
            >
              <button
                type="button"
                :disabled="tabIsLocked"
                class="btn btn-outline-dark"
                :class="{
                  active: tab.drawing_type == 'any',
                }"
                @click="setTabDrawingType('any')"
              >
                Any
              </button>
              <button
                v-for="selectableType in selectableTypes"
                type="button"
                :disabled="tabIsLocked"
                class="btn btn-outline-dark"
                :class="{
                  active: tab.drawing_type === selectableType,
                }"
                @click="setTabDrawingType(selectableType)"
              >
                <i class="fal" :class="getAppIconClass(selectableType)" />
              </button>
              <button
                type="button"
                :disabled="tabIsLocked"
                class="btn btn-outline-dark"
                :class="{
                  active: tab.drawing_type == 'non-spatial',
                }"
                @click="setTabDrawingType('non-spatial')"
              >
                Non-spatial
              </button>

              <button
                v-if="hasAccessToHedge"
                type="button"
                class="btn btn-outline-dark"
                :disabled="tabIsLocked"
                :class="{
                  active: tab.drawing_type == 'hedge',
                }"
                @click="setTabDrawingType('hedge')"
              >
                Hedge
              </button>
            </div>
          </div>
        </div>

        <AllowCollectionOnPoi
          v-if="isAllowCollectionOnPoiAvailable"
          class="mb-3"
          :allowCollectionOnPoi="tab.allow_collection_on_poi"
          :poiAppId="tab.poi_app_id"
          :poiApps="poiApps"
          @change="handleAllowCollectionOnPoiChange"
        />

        <div
          v-if="!['any', 'hedge'].includes(tab.drawing_type)"
          class="form-group position-relative mb-3"
        >
          <label class="form-label">
            Colour {{ tabColour ? '(' + tabColour + ')' : '' }}
          </label>

          <div>
            <input
              v-model="tabColour"
              type="color"
              :disabled="tabIsLocked || !isColorChangeable"
              :title="colorPickerTitle"
              class="form-control"
            />
          </div>
        </div>

        <div v-if="tab.drawing_type == 'point'" class="form-group mb-3">
          <label class="form-label"> Icon </label>

          <MarkerPicker
            :appId="2"
            :selectedMarkerId="pointIcon + 1"
            @markerSelect="onMarkerSelect"
          />
        </div>

        <div
          v-if="tab.drawing_type == 'polygon' || tab.drawing_type == 'polyline'"
          class="mb-3"
        >
          <div v-if="tab.drawing_type == 'polygon'" class="form-group mb-3">
            <label class="form-label"> Choose a fill pattern: </label>

            <div>
              <div class="btn-group">
                <button
                  type="button"
                  :disabled="tabIsLocked"
                  class="btn btn-outline-dark"
                  :class="{
                    active: drawingProperties.fillStyle == 0,
                  }"
                  @click="setFillPattern(0)"
                >
                  Overlay
                </button>
                <button
                  type="button"
                  :disabled="tabIsLocked"
                  class="btn btn-outline-dark"
                  :class="{
                    active: drawingProperties.fillStyle == 1,
                  }"
                  @click="setFillPattern(1)"
                >
                  No Overlay
                </button>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label class="form-label"> Choose a outline pattern: </label>

            <div>
              <div class="btn-group">
                <button
                  type="button"
                  :disabled="tabIsLocked"
                  class="btn btn-outline-dark"
                  :class="{
                    active: drawingProperties.outlineStyle == 0,
                  }"
                  @click="setOutlinePattern(0)"
                >
                  Solid
                </button>
                <button
                  type="button"
                  :disabled="tabIsLocked"
                  class="btn btn-outline-dark"
                  :class="{
                    active: drawingProperties.outlineStyle == 1,
                  }"
                  @click="setOutlinePattern(1)"
                >
                  Dashed
                </button>
                <button
                  type="button"
                  :disabled="tabIsLocked"
                  class="btn btn-outline-dark"
                  :class="{
                    active: drawingProperties.outlineStyle == 2,
                  }"
                  @click="setOutlinePattern(2)"
                >
                  Dotted
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 pl-0">
      <input
        id="tabIsLocked"
        ref="tabIsLocked"
        v-model="tabIsLocked"
        class="form-check-input"
        type="checkbox"
        data-cy="data-type-name-prefix"
      />
      <label class="form-check-label user-select-none" for="tabIsLocked">
        {{ tabIsLocked ? 'Unlock' : 'Lock' }} App styling
      </label>
    </div>

    <div v-if="isStylingRulesAvailable" class="mb-3">
      <button
        type="button"
        class="btn btn-light w-100 p-3 fw-bold"
        @click="handleStylingRulesClick"
      >
        Styling Rules
      </button>
    </div>

    <SubFoldersInput
      v-if="isSubFoldersInputVisible"
      v-model="subFolders"
      class="mb-2 pl-0"
      :checkIsSubFolderRemovable="checkIsSubFolderRemovable"
    />

    <div class="form-check mt-3 mb-2">
      <input
        id="item_limit"
        v-model="hasAppItemLimit"
        class="form-check-input"
        type="checkbox"
      />
      <label class="form-check-label" for="item_limit">
        Would you like to limit number of times app can be created?
      </label>
    </div>
    <div v-if="hasAppItemLimit" class="form-group mb-3">
      <label class="form-label">App Creation Limit</label>
      <div class="input-group">
        <input v-model="appItemLimit" type="number" class="form-control" />
      </div>
    </div>

    <LinkedAppList
      v-if="isLinkedAppListVisible"
      class="mb-3"
      :app="tab"
      :linkedApps="linkedApps"
      @change="handleLinkConfigChange"
    />

    <DefaultFigures
      v-if="isDefaultFiguresVisible"
      class="mb-3"
      :app="tab"
      @change="handleDefaultFigureIdsChange"
    />

    <Teleport v-if="isStylingRuleModalVisible" to="body">
      <StylingRuleModal
        :app="tab"
        @save="handleStylingRuleModalSave"
        @close="handleStylingRuleModalClose"
      />
    </Teleport>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import useStylingRules from '@/js/composables/useStylingRules';
import {
  getAppIconClass,
  getLinkFields,
  getRenderableAppLinkConfig,
} from '@component-library/business-logic/app';
import InfoButton from '@component-library/components/InfoButton.vue';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import { FEATURES, hasAccess } from '@component-library/feature-manager.ts';
import {
  FieldTypeIds,
  checkIsFieldEligibleForItemTitle,
} from '@component-library/fields';
import { getAppReferenceError } from '@component-library/gather';
import MarkerPicker from '@component-library/widgets/marker-picker/index.vue';
import { LayerType } from '@maps/lib/olbm/layer/types';
import _cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'vuex';
import AllowCollectionOnPoi from './AllowCollectionOnPoi.vue';
import DefaultFigures from './DefaultFigures.vue';
import UseFieldAsItemTitle from './UseFieldAsItemTitle.vue';
import LinkedAppList from './linking/LinkedAppList.vue';
import SubFoldersInput from './options/SubFoldersInput.vue';
import StylingRuleModal from './styling-rule/StylingRuleModal.vue';
import { checkIsMobile } from '@component-library/utils';
import { Teleport } from 'vue';

const POI_SYSTEM_REFERENCE = 'point_of_interest';

export default {
  name: 'TemplateTabEdit',
  props: ['tab', 'otherTabs'],
  components: {
    SubFoldersInput,
    MarkerPicker,
    UseFieldAsItemTitle,
    StylingRuleModal,
    AllowCollectionOnPoi,
    InfoButton,
    InputCheckbox,
    LinkedAppList,
    DefaultFigures,
    Teleport,
  },
  setup(props) {
    const { isAvailable: isStylingRulesAvailable } = useStylingRules(props.tab);

    const selectableTypes = [
      LayerType.POINT,
      LayerType.POLYGON,
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
    ];

    return {
      isStylingRulesAvailable,
      selectableTypes,
      getAppIconClass,
    };
  },
  data: () => ({
    appTitle: null,
    tabIsLocked: false,
    tabPrefix: null,
    hasReadOnlyTitles: false,
    tabColour: null,
    pointIcon: null,
    drawingProperties: {},
    // A custom marker's colour could be unchangeable.
    isColorChangeable: true,
    isStylingRuleModalVisible: false,
    hasReadOnlyItemTitles: false,
    hasAppItemLimit: false,
  }),
  watch: {
    tab() {
      this.setTabFields();
    },
    tabIsLocked(updated) {
      this.$emit('updateTab', {
        key: 'is_locked',
        value: updated,
      });
    },
    'tab.drawing_type'(value) {
      this.isColorChangeable = true;
    },
    hasAppItemLimit() {
      if (!this.hasAppItemLimit) {
        this.appItemLimit = null;
      }
    },
    hasReadOnlyItemTitles() {
      this.$emit('updateTab', {
        key: 'has_read_only_item_titles',
        value: this.hasReadOnlyItemTitles,
      });
    },
    tabColour() {
      this.updateTabColour();
    },
  },
  computed: {
    ...mapState({
      tempStylingRules: (state) => state.tempStylingRules,
    }),
    isSubFoldersInputVisible() {
      return ['any', 'point'].includes(this.tab.drawing_type);
    },
    colorPickerTitle() {
      return this.tab.drawing_type === 'point' && !this.isColorChangeable
        ? 'The colour of the selected marker is not changeable.'
        : '';
    },
    subFolders: {
      get() {
        return this.tab.sub_folders;
      },
      set(updated) {
        this.onSubFoldersChanged(updated);
      },
    },
    appItemLimit: {
      get() {
        return this.tab.item_limit || null;
      },
      set(value) {
        this.$emit('updateTab', {
          key: 'item_limit',
          value,
        });
      },
    },
    invalidNameError() {
      return getAppReferenceError(this.tab);
    },
    availableFieldsForItemTitle() {
      return this.tab.sections.reduce((accu, section) => {
        for (let i = 0; i < section.template_fields.length; i++) {
          const field = section.template_fields[i];
          if (checkIsFieldEligibleForItemTitle(field)) {
            accu.push(field);
          }
        }
        return accu;
      }, []);
    },
    hasAccessToHedge() {
      return hasAccess(FEATURES.MAPS_HEDGE);
    },
    poiApps() {
      return this.otherTabs.filter((otherTab) =>
        ['any', 'non-spatial'].includes(otherTab.drawing_type)
      );
    },
    isAllowCollectionOnPoiAvailable() {
      return [
        LayerType.POLYGON,
        LayerType.POLYLINE,
        LayerType.ARROW,
        LayerType.RECTANGLE,
        LayerType.CIRCLE,
        LayerType.HEDGE,
      ].includes(this.tab.drawing_type);
    },
    linkedApps() {
      return getLinkFields(this.tab).reduce((accu, lf) => {
        const linkedApp = this.otherTabs.find(
          (app) =>
            app.title === lf.options?.template_tab_title &&
            ['point', 'any'].includes(app.drawing_type)
        );
        if (linkedApp) {
          accu.push(linkedApp);
        }
        return accu;
      }, []);
    },
    isLinkedAppListVisible() {
      return (
        ['any', 'non-spatial'].includes(this.tab.drawing_type) &&
        this.linkedApps.length > 0
      );
    },
    isDefaultFiguresVisible() {
      return (
        this.tab.drawing_type !== 'non-spatial' ||
        !!getRenderableAppLinkConfig(this.tab, [this.tab, ...this.otherTabs])
      );
    },
  },
  methods: {
    ...mapActions(['setTempStylingRules']),
    setTabFields() {
      this.appTitle = this.tab.title;
      this.tabIsLocked = this.tab.is_locked;
      this.tabPrefix = this.tab.prefix;
      this.tabColour = this.tab.drawing_colour;
      this.pointIcon = this.tab.point_icon;
      this.drawingProperties = this.tab.drawing_properties || {
        fillStyle: 0,
        outlineStyle: 0,
      };
      this.hasAppItemLimit = !!this.tab.item_limit;
      this.hasReadOnlyItemTitles = this.tab.has_read_only_item_titles;
    },
    updateAppTitle() {
      if (!this.appTitle) {
        return;
      }

      if (this.appTitle === this.tab.title) {
        return;
      }

      this.$emit('updateTab', {
        key: 'title',
        value: this.appTitle,
      });
    },
    updateTabPrefix() {
      this.$emit('updateTab', {
        key: 'prefix',
        value: this.tabPrefix,
      });

      if (!this.tabPrefix || this.tabPrefix.length === 0) {
        this.hasReadOnlyItemTitles = false;
      }
    },
    setTabDrawingType(type) {
      this.$emit('updateTab', {
        key: 'drawing_type',
        value: type,
      });
    },
    updateTabColour: _debounce(function () {
      this.$emit('updateTab', {
        key: 'drawing_colour',
        value: this.tabColour,
      });
    }, 500),
    setTabPointIcon(icon) {
      this.pointIcon = icon;
      this.$emit('updateTab', {
        key: 'point_icon',
        value: icon,
      });
    },
    setFillPattern(style) {
      this.drawingProperties.fillStyle = style;

      this.$emit('updateTab', {
        key: 'drawing_properties',
        value: this.drawingProperties,
      });
    },
    setOutlinePattern(style) {
      this.drawingProperties.outlineStyle = style;

      this.$emit('updateTab', {
        key: 'drawing_properties',
        value: this.drawingProperties,
      });
    },
    async checkIsSubFolderRemovable(index) {
      const { data: sampleCount } = await axios.get(
        `/api/template/${this.tab.id}/sub_folders/${index}/sample_count`
      );
      const result = sampleCount === 0;
      if (!result) {
        const subFolder = this.tab.sub_folders[index];
        this.$toastStore.error(
          `The sub folder "${subFolder}" can not be deleted because it has ${sampleCount} items inside.`
        );
      }
      return result;
    },
    updateTemplateSections() {
      this.$emit('updateTemplateSections', this.sections);
    },
    onSubFoldersChanged(value) {
      this.$emit('updateTab', {
        key: 'sub_folders',
        value,
      });
    },

    onMarkerSelect(marker) {
      this.setTabPointIcon(marker.id - 1);
      this.isColorChangeable = marker.is_color_changeable;
    },
    handleUseFieldAsTitleChange(value) {
      this.$emit('updateTab', {
        key: 'item_title_field_id',
        value,
      });
    },
    async handleStylingRulesClick() {
      await this.setTempStylingRules(_cloneDeep(this.tab.styling_rules));
      this.isStylingRuleModalVisible = true;
    },
    handleStylingRuleModalClose() {
      this.isStylingRuleModalVisible = false;
    },
    handleStylingRuleModalSave(onFinished) {
      this.$emit('updateTab', {
        key: 'styling_rules',
        value: this.tempStylingRules,
        onFinished,
      });
    },
    handleAllowCollectionOnPoiChange(update) {
      this.$emit('updateTabInBulk', {
        update,
        expectedTabId: this.tab.id,
        onFinished: async () => {
          const { sections } = this.tab;
          const { allow_collection_on_poi, poi_app_id } = update;

          if (!poi_app_id) {
            return;
          }

          const sectionIndex = sections.findIndex(
            (section) => section.system_reference === POI_SYSTEM_REFERENCE
          );

          if (!allow_collection_on_poi) {
            // Remove the section [point_of_interest]
            if (sectionIndex === -1) {
              throw `Can't delete the section [point_of_reference] because it was not found.`;
            }
            this.$emit('remove-section', sectionIndex);
            return;
          }

          const { title: poiAppTitle } = this.otherTabs.find(
            (otherTab) => otherTab.id === poi_app_id
          );

          const section = sections[sectionIndex];
          if (!section) {
            // Add the section [point_of_interest]
            const element = {
              is_repeatable: true,
              label: 'Data Point',
              system_reference: POI_SYSTEM_REFERENCE,
              primary_field_id: null,
              is_number_used_as_title: true,
              template_fields: [
                {
                  field_type_id: FieldTypeIds.REFERENCE,
                  icon: 'fa-link',
                  label: 'Data Form',
                  system_reference: 'data_form',
                  options: {
                    conditions: [],
                    options: [],
                    groups: [],
                    groupConditions: [],
                    defaults: [],
                    all_conditions_match: null,
                    is_readonly: false,
                    template_tab_title: poiAppTitle,
                  },
                },
                {
                  field_type_id: FieldTypeIds.NUMBER,
                  icon: 'fa-lambda',
                  label: 'Longitude',
                  system_reference: 'longitude',
                  options: {
                    conditions: [],
                    options: [],
                    groups: [],
                    groupConditions: [],
                    defaults: [],
                    all_conditions_match: null,
                    is_readonly: false,
                  },
                },
                {
                  field_type_id: FieldTypeIds.NUMBER,
                  icon: 'fa-lambda',
                  label: 'Latitude',
                  system_reference: 'latitude',
                  options: {
                    conditions: [],
                    options: [],
                    groups: [],
                    groupConditions: [],
                    defaults: [],
                    all_conditions_match: null,
                    is_readonly: false,
                  },
                },
              ],
            };
            this.$emit('addSection', { element, index: sections.length });
          } else {
            const fieldIndex = section.template_fields.findIndex(
              (field) => field.system_reference === 'data_form'
            );
            this.$emit('updateFieldOptions', {
              sectionIndex,
              fieldIndex,
              options: {
                ...section.template_fields[fieldIndex].options,
                template_tab_title: poiAppTitle,
              },
            });
          }
        },
      });
    },
    handleLinkConfigChange({ linkedAppId, linkConfig }) {
      this.$emit('updateTab', {
        key: 'linked_app_link_config',
        value: { linkedAppId, linkConfig },
      });
    },
    handleDefaultFigureIdsChange(value) {
      this.$emit('updateTab', {
        key: 'default_figure_ids',
        value,
      });
    },
  },
  beforeMount() {
    this.setTabFields();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs['appTitle'] && !checkIsMobile()) {
        this.$refs['appTitle'].focus();
      }
    });
  },
};
</script>

<style scoped>
.marker-icon-btn {
  width: 16px;
}

#tabIsLocked {
  display: none;
}
#tabIsLocked + label:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 1.2em;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
}
/* unchecked */
#tabIsLocked + label:before {
  content: '\f3c1';
  margin-right: 2px;
}
/* checked */
#tabIsLocked:checked + label:before {
  content: '\f023';
  margin-right: 6px;
}

.overlay-container {
  position: relative;
}

.lock-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: gray;
  opacity: 0.3;
}

.icon-overlay {
  font-size: 20em;
  opacity: 0.5;
  color: white;
}
</style>
