<script setup lang="ts">
import AdminOfflineUploadModal from '@/js/components/offline-admin/UploadModal.vue';
import OfflineUploadModal from '@/js/components/offline-upload/Modal.vue';
import useRequestOfflineSetup from '@/js/composables/useRequestOfflineSetup';
import { useStore } from '@/js/store';
import { auth } from '@component-library/auth';
import useChatProvider from '@component-library/chat-service';
import Spinner from '@component-library/components/Spinner.vue';
import { DATANEST_URL } from '@component-library/env';
import EventBus from '@component-library/EventBus';
import DropdownNavItem from '@component-library/navigation/DropdownNavItem.vue';
import ApplicationNavigation from '@component-library/navigation/index.vue';
import { useNavigationStore } from '@component-library/store/navigation';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { useProjectStore } from '@component-library/store/project';
import { useToastStore } from '@component-library/store/toasts';
import AppSwitcherDropdown from '@component-library/switcher/AppDropdown.vue';
import { goToApp } from '@component-library/switcher/handover';
import { checkIsMobile, getDeviceName } from '@component-library/utils';
import localForage from 'localforage';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import cocApi from '../project/chain-of-custody/api/index.js';
import { loadProjectDetails } from '../project/list/api';
import OfflineSetupModal from './components/OfflineSetupModal.vue';
import ProgressBar from './components/ProgressBar.vue';

const router = useRouter();
const route = useRoute();

const store = useStore();
const navigationStore = useNavigationStore();
const offlineStorageManagerStore = useOfflineStorageManagerStore() as any;
const chatProvider: any = useChatProvider();

const offlineStorageUsed = ref<any>(null);
const showSwUpdated = ref(false);
const isNetworkOnline = ref(true);
const quota = ref<any>(null);
const hasCoc = ref(false);
const hasOfflineUpload = ref(false);
const showAdminUploadModal = ref(false);
const showOfflineSetupModal = ref(false);

const project = computed<any>(() => store.state.project);
const isOnline = computed(() => store.state.isOnline);

const authUser = computed(() => {
  return auth.user();
});

const hasLiveChat = computed(() => chatProvider.hasLiveChat);

const logo = computed(() => {
  return '/images/datanest-logo-title.svg';
});

const networkIssue = computed(() => {
  return isNetworkOnline.value != isOnline.value;
});

const isMobile = computed(() => {
  return checkIsMobile() || auth.user().role === 'admin';
});

const isMapView = computed(() => {
  return (
    route.path.endsWith('/map') &&
    !!project.value &&
    !project.value.is_gather_non_spatial_view
  );
});

const isNonSpatialView = computed(() => {
  return (
    route.path.endsWith('/map') &&
    !!project.value &&
    project.value.is_gather_non_spatial_view
  );
});

watch(isOnline, (newValue) => {
  if (!newValue) {
    hasCoc.value = false;
  }
});

watch(project, () => {
  if (hasCoc.value) {
    return;
  }
  updateHasCoc();
});

const checkQuota = async () => {
  if (navigator.storage && navigator.storage.estimate) {
    quota.value = await navigator.storage.estimate();
    const percentageUsed = (quota.value.usage / quota.value.quota) * 100;

    offlineStorageUsed.value = percentageUsed;
  }
};

const openLiveChat = () => {
  if (auth.impersonating()) {
    const toastStore = useToastStore();
    toastStore.error('Cannot open chat while impersonating');
    throw new Error('Cannot open chat while impersonating');
  }
  let user = auth.user();
  let projectId = project.value?.project_id;
  chatProvider.open(user, projectId);
};

const openHelpCenter = () => {
  window.open(DATANEST_URL + '/support', '_blank');
};

const gotoAccount = () => {
  EventBus.$emit('goToApp', {
    app: 'hub',
    route: 'account',
  });
};

const gotoProjectSettings = () => {
  EventBus.$emit('goToApp', {
    app: 'hub',
    route: 'project_settings',
  });
};

const logoutSession = () => {
  auth.logout().then(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    auth.clear();
    goToApp({
      app: 'hub',
      route: 'login',
    });
  });
};

const loadProjectData = () => {
  loadProjectDetails(project.value.project_id).then((response) => {
    const projectStore = useProjectStore();
    projectStore.updateProject(response.data.project);
  });
};

const isRouteActive = (path) => {
  return route.path.includes(path);
};

const goToRouteViaDropdown = (to: string, newTab = false) => {
  // If already on 'map' page toggle non spatial.
  if (to.endsWith('/map') && route.path.endsWith('/map')) {
    EventBus.$emit('changeViewType', !project.value.is_gather_non_spatial_view);
    return;
  }

  if (!newTab) {
    router.push(to);
  } else {
    window.open(to, '_blank');
  }
};

const updateOnlineStatus = () => {
  isNetworkOnline.value = navigator.onLine;
};

const reloadWindow = () => {
  window.location.reload();
};

const updateHasCoc = () => {
  if (!isOnline.value || !project.value?.project_id) return;

  cocApi.getHasCoc().then(({ data }) => {
    hasCoc.value = data.has_coc;
  });
};

const openProject = (project) => {
  window.location.href = `/${project.project_id}/map`;
  window.location.reload();
};

const formatQuota = (_quota) => {
  const usageMB = Math.round(_quota.usage / 1048576);
  const totalMB = Math.round(_quota.quota / 1048576);

  if (totalMB < 1024) {
    return `${usageMB.toFixed(2)} MB/${totalMB.toFixed(2)} MB`;
  } else {
    const usageGB = usageMB / 1024;
    const totalGB = totalMB / 1024;
    return `${usageGB.toFixed(2)} GB/${totalGB.toFixed(2)} GB`;
  }
};

document.addEventListener(
  'swUpdated',
  () => {
    showSwUpdated.value = true;
  },
  { once: true }
);

onMounted(async () => {
  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  store.dispatch('updateNetworkState', navigator.onLine);

  updateOnlineStatus();
  checkQuota();

  if (isOnline.value && project.value) {
    if (offlineStorageManagerStore.offlineProjects.length > 0) {
      hasOfflineUpload.value = true;
      if (
        offlineStorageManagerStore.offlineProjects.filter(
          (p) =>
            p.samples.filter((s) => s.is_created_offline || s.is_edited_offline)
              .length > 0
        ).length > 0
      ) {
        navigationStore.showGatherUploadModal = true;
      }
    }

    const hasAcknowledgedOfflineSetup =
      localStorage.getItem('hasAcknowledgedOfflineSetup') === 'true';

    if (
      !navigationStore.showGatherUploadModal &&
      !hasAcknowledgedOfflineSetup &&
      isMobile.value
    ) {
      showOfflineSetupModal.value = true;
    }

    if (
      hasAcknowledgedOfflineSetup &&
      isMobile.value &&
      !isRouteActive('handover')
    ) {
      await localForage.setItem('device', getDeviceName());

      if (!auth.user().company.is_force_local_gather_offline_mode) {
        const { subscribeToNotifications } = useRequestOfflineSetup();
        subscribeToNotifications();
      }
    }
  }

  updateHasCoc();
});

onBeforeUnmount(() => {
  window.removeEventListener('online', updateOnlineStatus);
  window.removeEventListener('offline', updateOnlineStatus);
});
</script>

<template>
  <div>
    <ApplicationNavigation
      :isAuthenticated="!!authUser"
      :isStagingEnvironment="
        !navigationStore.demoModeEnabled && !navigationStore.isProduction
      "
      moduleName="gather"
      :logo="logo"
      class="gather"
    >
      <template #appSwitcher>
        <AppSwitcherDropdown
          v-if="isOnline && authUser"
          :rightSide="true"
          class="me-3"
          @loadProjectData="loadProjectData"
        />

        <div v-if="!isOnline" class="badge bg-danger ms-2 d-block">Offline</div>
      </template>
      <template #mobileMenu>
        <ProgressBar
          v-if="!isOnline && quota"
          :progress="offlineStorageUsed"
          title="storage"
          :message="formatQuota(quota)"
        />

        <DropdownNavItem v-if="project && isOnline" :hasPadding="true">
          <template #triggerBtn>
            <i class="fal fa-fw fa-map-marked-alt"></i>
            <span>Project</span>
          </template>
          <template #dropdownItems>
            <div
              class="dropdown-item"
              @click="goToRouteViaDropdown(`/${project.project_id}/map`)"
              @mousedown.middle="
                goToRouteViaDropdown(`/${project.project_id}/map`, true)
              "
            >
              <i class="fal fa-map-marker-plus fa-fw" /> Gather Data
            </div>
            <div
              class="dropdown-item"
              :class="{
                active: isRouteActive('/editor'),
              }"
              @click="
                goToRouteViaDropdown(`/template/${project.project_id}/editor`)
              "
              @mousedown.middle="
                goToRouteViaDropdown(
                  `/template/${project.project_id}/editor`,
                  true
                )
              "
            >
              <i class="fal fa-tasks-alt fa-fw" />
              App Editor
            </div>
            <div
              v-if="hasCoc"
              class="dropdown-item"
              @click="
                goToRouteViaDropdown(`/${project.project_id}/chain-of-custody`)
              "
              @mousedown.middle="
                goToRouteViaDropdown(
                  `/${project.project_id}/chain-of-custody`,
                  true
                )
              "
            >
              <i class="fal fa-vial fa-fw"></i>
              <span>CoC</span>
            </div>
            <div class="dropdown-item" @click="gotoProjectSettings">
              <i class="fal fa-cog fa-fw" />
              Settings
            </div>
            <template v-if="isRouteActive('/map')">
              <div
                class="dropdown-item"
                :class="{
                  active: isMapView,
                }"
                @click="EventBus.$emit('changeViewType', false)"
              >
                <i class="fal fa-fw fa-map" /> Map View
              </div>
              <div
                class="dropdown-item"
                :class="{
                  active: isNonSpatialView,
                }"
                @click="EventBus.$emit('changeViewType', true)"
              >
                <i class="fal fa-fw fa-bars" /> Non-spatial View
              </div>
            </template>

            <div
              v-if="authUser.role === 'admin'"
              class="dropdown-item"
              @click="showAdminUploadModal = true"
            >
              <i class="fal fa-fw fa-download" /> Admin Upload
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem v-if="isOnline" :hasPadding="true">
          <template #triggerBtn>
            <i class="fal fa-user me-0"></i>
          </template>
          <template #dropdownItems>
            <div class="dropdown-item" @click="gotoAccount">
              <i class="fal fa-user-alt fa-fw"></i>
              Account
            </div>

            <div class="dropdown-item" @click="openHelpCenter">
              <i class="fal fa-user-shield fa-fw"></i>
              <span>Help Center</span>
            </div>

            <div v-if="hasLiveChat" class="dropdown-item" @click="openLiveChat">
              <i class="fal fa-comment-dots fa-fw"></i>
              <span>Talk to us?</span>
            </div>

            <button
              v-if="isMobile"
              class="dropdown-item"
              @click="showOfflineSetupModal = true"
            >
              <i class="fal fa-bell fa-fw"></i>Manage Offline Settings
            </button>

            <div class="dropdown-item" @click="logoutSession">
              <i class="fal fa-sign-out fa-fw"></i>
              <span>Sign Out</span>
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem
          v-if="
            offlineStorageManagerStore &&
            offlineStorageManagerStore.offlineProjects.length > 0
          "
          :isActive="false"
          :hasPadding="true"
          class="d-lg-none"
        >
          <template #triggerBtn>
            <i class="fal fa-fw fa-cloud-upload-alt"></i>
          </template>
          <template #dropdownItems>
            <div
              v-for="offlineProject in offlineStorageManagerStore.offlineProjects"
              :key="`offline-project-${offlineProject.project_id}`"
              class="dropdown-item py-3 d-flex align-items-center"
              @click="openProject(offlineProject)"
            >
              <i
                class="fa-map"
                :class="[
                  project && offlineProject.project_id === project.project_id
                    ? 'fas'
                    : 'fal',
                ]"
              />
              <div class="d-flex flex-column">
                <span class="d-block fw-medium overflow-hidden mb-0 text-wrap">
                  {{
                    offlineProject.project_number +
                    (offlineProject.project_name
                      ? ' - ' + offlineProject.project_name
                      : '')
                  }}
                </span>
                <small>{{ offlineProject.project_client }}</small>
              </div>
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem
          v-if="
            isOnline &&
            project?.synced_offline_requests &&
            project.synced_offline_requests > 0 &&
            !offlineStorageManagerStore.isDownloading
          "
          :isActive="false"
          :hasPadding="true"
        >
          <template #triggerBtn>
            <i class="fal fa-fw fa-cloud-upload-alt"></i>
          </template>
          <template #dropdownItems>
            <div
              class="dropdown-item"
              @click.prevent.stop="navigationStore.showGatherUploadModal = true"
            >
              Upload Offline Data
            </div>
          </template>
        </DropdownNavItem>
      </template>
      <template #rightOptions>
        <div class="d-flex">
          <a
            v-if="offlineStorageManagerStore.isDownloading"
            class="btn btn-header"
            href="#"
          >
            <Spinner small />
          </a>
        </div>
      </template>
    </ApplicationNavigation>

    <div
      style="
        position: absolute;
        bottom: 15px;
        z-index: 9999;
        left: 0;
        right: 0;
        width: 350px;
        margin: 0 auto;
        overflow: hidden;
      "
    >
      <transition name="dropdown">
        <div
          v-show="showSwUpdated || networkIssue"
          class="p-3 text-center"
          style="
            box-shadow: rgb(0 0 0 / 10%) 0px 3px 4px 0px,
              rgb(0 0 0 / 5%) 0px 3px 3px -2px, rgb(0 0 0 / 3%) 0px 1px 8px 0px;
            background: #ffffff;
          "
        >
          <h6 class="mb-3">
            <template v-if="networkIssue">
              Your network status has changed, please refresh to get the latest
              information
            </template>
            <template v-else>
              Datanest has been updated, please refresh to get the latest
              version
            </template>
          </h6>
          <button
            type="button"
            class="btn btn-primary w-100"
            @click="reloadWindow"
          >
            Refresh
          </button>
        </div>
      </transition>
    </div>

    <OfflineUploadModal
      v-if="navigationStore.showGatherUploadModal"
      @close="navigationStore.showGatherUploadModal = false"
    />

    <AdminOfflineUploadModal
      v-if="showAdminUploadModal"
      @close="showAdminUploadModal = false"
    />

    <OfflineSetupModal
      v-if="showOfflineSetupModal"
      @close="showOfflineSetupModal = false"
    />
  </div>
</template>
