<script lang="ts" setup>
import { auth } from '@component-library/auth';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const isLoggedIn = computed(() => {
  return auth.check();
});
</script>

<template>
  <div
    class="d-flex align-items-center justify-content-center flex-column py-4 text-center flex-fill"
  >
    <div class="col-md-8 col-12 text-center">
      <h1
        class="font-weight-bold"
        style="font-size: 8rem; color: #4e0695; font-weight: 700"
      >
        404
      </h1>
      <div class="mb-4"><strong>OOPS!</strong> Something went wrong here.</div>
      <div class="d-flex gap-2 justify-content-center">
        <button
          class="btn btn-outline-secondary"
          @click.prevent="router.go(-1)"
        >
          Go Back
        </button>
        <template v-if="isLoggedIn">
          <router-link class="btn btn-primary" to="/"> Go to Home </router-link>
        </template>
        <template v-else>
          <router-link class="btn btn-primary" to="/login">
            Go to Login
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
