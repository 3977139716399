import { SUPPORTED_VIDEO_TYPES, VIDEO_FILE_EXTENSION } from "../business-model/media";

export function getSupportedVideoFileExtensions(): VIDEO_FILE_EXTENSION[] {
  return SUPPORTED_VIDEO_TYPES.map((item) => item.fileExtension);
}

export function getVideoMimeTypeByFileExtension(
  fileExtension: VIDEO_FILE_EXTENSION
): string | null {
  return (
    SUPPORTED_VIDEO_TYPES.find(
      (item) => item.fileExtension === fileExtension
    )?.mimeType ?? null
  );
}
