<script lang="ts" setup>
import { computed } from 'vue';
import moment from 'moment';
import VersionValue from './VersionValue.vue';
import { useStore } from 'vuex';
import type {
  InputValue,
  GatherField,
  Section,
} from '@component-library/gather';
import { User } from '@component-library/user';

const props = defineProps<{
  sample: object;
  versionCreatedAts: any[];
  inputValues: InputValue[];
  users: User[];
  template: { sections: Section[] };
}>();

const emit = defineEmits<{
  (event: 'revertValuesToVersion', data: { versionCreatedAt: any }): void;
}>();

const store = useStore();

const getDatetimeFormatByCountry = computed(
  () => store.getters['get_datetime_format_by_country']
);

function getInputValue(
  section: Section,
  sectionIndex: number,
  field: GatherField
) {
  const value = props.inputValues.find(
    (v) =>
      v.template_section_id == section.id &&
      v.template_field_id == field.id &&
      v.template_section_index == sectionIndex
  );

  return value;
}

function getSectionIndex(section: Section) {
  const sectionIndexes = props.inputValues
    .filter((v) => v.template_section_id == section.id)
    .map((v) => v.template_section_index);

  return sectionIndexes.length != 0 ? Math.max(...sectionIndexes) + 1 : 0;
}

function formatVersionCreatedAt(value: any) {
  return moment.utc(value).local().format(getDatetimeFormatByCountry.value);
}
</script>

<template>
  <div class="table-responsive" style="max-height: 55vh">
    <table class="table">
      <thead>
        <th class="fw-bold" style="min-width: 220px">Version</th>
        <th
          v-for="(vca, vcaIndex) in versionCreatedAts"
          :key="vca"
          class="fw-bold text-center"
        >
          <button
            v-if="vcaIndex < versionCreatedAts.length - 1"
            class="btn btn-sm me-1"
            style="padding: 0.25rem 0.5rem"
            @click="$emit('revertValuesToVersion', { versionCreatedAt: vca })"
          >
            <i class="fas fa-history" />
          </button>
          {{ vcaIndex + 1 }}
        </th>
      </thead>
      <tbody v-if="template && template.sections" class="text-left">
        <!-- Show the time when the version was created-->
        <tr class="bg-light">
          <td class="fw-bold">Created at</td>
          <td v-for="vca in versionCreatedAts" class="text-center">
            {{ formatVersionCreatedAt(vca) }}
          </td>
        </tr>

        <template
          v-for="section in template.sections"
          :key="'section-' + section.id"
        >
          <tr>
            <td
              colspan="100%"
              class="fw-bold text-white"
              style="background-color: #8a6aa5"
            >
              <span class="position-sticky" style="left: 12px">{{
                section.label
              }}</span>
            </td>
          </tr>

          <template v-for="sectionIndex in getSectionIndex(section)">
            <tr
              v-for="field in section.template_fields"
              :class="{
                'bg-light': sectionIndex % 2 == 0,
                'primary-field-title': section.primary_field_id == field.id,
              }"
            >
              <td class="fw-bold">{{ field.label }}</td>
              <VersionValue
                v-for="(vca, vcaIndex) in versionCreatedAts"
                :key="
                  section.id +
                  '-' +
                  sectionIndex +
                  '-field' +
                  field.id +
                  '-' +
                  vcaIndex
                "
                :field="field"
                :value="getInputValue(section, sectionIndex - 1, field)"
                :versionCreatedAt="vca"
                :previousVersionCreatedAt="
                  vcaIndex > 0 ? versionCreatedAts[vcaIndex - 1] : undefined
                "
                :versionNumber="vcaIndex + 1"
                :isLastVersion="vcaIndex + 1 === versionCreatedAts.length"
                :users="users"
                @revertValuesToVersion="
                  (data) => $emit('revertValuesToVersion', data)
                "
              />
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
