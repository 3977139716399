<script setup lang="ts">
import Map from '@/js/modules/project/map/components/Map.vue';
import useFigureStore from '@/js/stores/figure';
import useSampleStore from '@/js/stores/sample';
import {
  StackableModalType,
  checkIsAlertUnsavedItemModal,
  checkIsAtTop,
  checkIsDeletePlainShapeModal,
  checkIsDeleteSampleModal,
  checkIsManagersModal,
  checkIsSampleModal,
  checkIsVersionControlModal,
  getTopModal,
} from '@/js/types/modal-stack';
import { auth } from '@component-library/auth';
import Field from '@component-library/classes/Field';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import ImageModal from '@component-library/components/ImageModal.vue';
import IndeterminateProgressBar from '@component-library/components/IndeterminateProgressBar.vue';
import NotifyModal from '@component-library/components/NotifyModal.vue';
import OfflineManagementModal from '@component-library/components/offline-management/SingleModal.vue';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import EventBus from '@component-library/EventBus';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import type { App, Item } from '@component-library/gather';
import { useCollectionStore } from '@component-library/store/collection';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { useProjectStore } from '@component-library/store/project';
import { useToastStore } from '@component-library/store/toasts';
import { goToApp } from '@component-library/switcher/handover';
import UnauthorizedModal from '@component-library/team/UnauthorizedModal.vue';
import { waitFor } from '@component-library/utils/wait-for';
import {
  LoadManager,
  checkIsValidLatLng,
  lonLatToLegacyLatLng,
} from '@maps/lib/olbm';
import { DrawingType } from '@maps/lib/olbm/app/types';
import { LayerUsageByType } from '@maps/lib/olbm/layer/constants';
import { checkIsSampleGroup } from '@maps/lib/olbm/layer/sample/utils';
import { LayerType } from '@maps/lib/olbm/layer/types';
import { getUsage } from '@maps/lib/olbm/layer/utils';
import { BasemapId } from '@maps/lib/olbm/types';
import axios from 'axios';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  watch,
} from 'vue';
import NonSpatialEditor from '../non-spatial-editor/index.vue';
import api from './api/index.js';
import AddressLookupModal from './components/AddressLookupModal.vue';
import AlertUnsavedItemModal from './components/AlertUnsavedItemModal.vue';
import LayerStylingOffcanvas from './components/LayerStylingOffcanvas.vue';
import ManagersModal from './components/ManagersModal.vue';
import MapActionMenu from './components/MapActionMenu.vue';
import ProjectInfoModal from './components/ProjectInfoModal.vue';
import SampleModal from './components/SampleModal.vue';
import TrackingActivityModal from './components/TrackingActivityModal.vue';
import VersionControlModal from './components/VersionControlModal.vue';
import ViewHeader from './components/ViewHeader.vue';
import ActionEvents from './events/ActionEvents';
import SampleEvents from './events/SampleEvents';
import createAreaFigureLayer from './helpers/createAreaFigureLayer';

const legacyStore = useLegacyStore();
const project = computed(() => legacyStore.state.project);
const isOnline = computed(() => legacyStore.state.isOnline);
const persistence = computed(() => legacyStore.state.persistence);
const isScaleVisible = computed(() => legacyStore.state.isScaleVisible);
const modalStack = computed(() => legacyStore.state.modalStack);

const toastStore = useToastStore();
const figureStore = useFigureStore();
const sampleStore = useSampleStore();
const offlineStorageManagerStore = useOfflineStorageManagerStore();

const map = ref<typeof Map | undefined>(undefined);
provide('map', map);

const isMapMounted = ref(false);
provide('isMapMounted', isMapMounted);

const collectionStore = useCollectionStore();
const gatherSchema = useGatherSchemaStore();

const sampleModal = ref<typeof SampleModal | null>(null);
provide('sampleModal', sampleModal);

const layerStylingOffcanvas = ref(null);
const nonSpatialEditor = ref(null);
const iosInvalidationCounter = ref(0);
const isNonSpatialView = ref(false);
const offlineZoomLevel = ref<number | null>(null);
const isMoving = ref(false);
const isDrawing = ref(false);
const isEditing = ref(false);
const isDuplicating = ref(false);
const sampleDataToModify = ref<any>(null);
const showSampleModal = ref(false);
const showProjectInfoModal = ref(false);
const showDeleteSampleModal = ref(false);
const showManagersModal = ref(false);
const showOfflineManagerModal = ref(false);
const showAddressLookupModal = ref(false);
const showVersionControlModal = ref(false);
const showTrackingActivityModal = ref(false);
const showAlertUnsavedItemModal = ref(false);
const templateTabs = ref<App[]>([]);
const templateTabToSelect = ref(null);
const imageURL = ref<string | Blob | null>(null);
const drawingType = ref(undefined);
const isTrackingAvailable = ref(false);
const isPoiEditingOn = ref(false);
// Used to delete a plain shape
const layerModelIdToDelete = ref<number | undefined>(undefined);
const isAppsLoaded = ref(false);

const selectedFigure = computed(() => {
  return figureStore.getSelectedFigure();
});

const projection = computed(() => {
  return selectedFigure.value?.view_projection ?? 'EPSG:3857';
});

const figureCenter = computed(() => {
  const { longitude: projectLongitude, latitude: projectLatitude } =
    figureStore.getProject();
  const projectLonLat = {
    longitude: projectLongitude,
    latitude: projectLatitude,
  };

  if (!selectedFigure.value) {
    return projectLonLat;
  }

  const { view_lng: viewLongitude, view_lat: viewLatitude } =
    selectedFigure.value;
  const viewLonLat = {
    longitude: viewLongitude,
    latitude: viewLatitude,
  };

  return !checkIsValidLatLng(lonLatToLegacyLatLng(viewLonLat))
    ? projectLonLat
    : viewLonLat;
});

const basemapId = computed<number | undefined | null>({
  get() {
    return selectedFigure.value?.basemap_index;
  },
  set(value) {
    if (selectedFigure.value && value !== undefined && value !== null) {
      figureStore.saveSelectedBasemapId(selectedFigure.value.id, value);
    }
  },
});

const isTracking = computed({
  get() {
    return persistence.value.isTracking;
  },
  set(value) {
    updatePersistence({
      isTracking: value,
    });
  },
});

const offlineProject = computed(() => {
  return offlineStorageManagerStore.getProjectById(project.value.project_id);
});

const isOffline = computed(() => {
  return !isOnline.value && offlineProject.value !== null;
});

const userAuthorized = computed(() => {
  if (!project.value || !project.value.users) {
    return true;
  }

  const authUser = auth.user();
  return (
    authUser.role == 'admin' ||
    project.value.user_id == authUser.user_id ||
    project.value.users.findIndex((u) => u.user_id == authUser.user_id) != -1
  );
});

const getImageURL = computed(() => {
  if (!imageURL.value) {
    return;
  }

  if (imageURL.value instanceof Blob) {
    return URL.createObjectURL(imageURL.value);
  }

  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (typeof imageURL.value === 'string' && base64regex.test(imageURL.value)) {
    return imageURL.value;
  }

  return `/api/images/value/${project.value.project_id}/${imageURL.value}`;
});

const isAllowCollectionOnPoiAvailable = computed(() => {
  if (!sampleDataToModify.value) {
    return false;
  }

  const { template_tab_id: appId } = sampleDataToModify.value;
  const app = appId
    ? templateTabs.value.find((_app) => _app.id === appId)
    : undefined;
  return app?.allow_collection_on_poi ?? false;
});

const alertUnsavedItemModalPayload = computed(() => {
  const topModal = getTopModal(modalStack.value);
  return topModal && checkIsAlertUnsavedItemModal(topModal)
    ? topModal.payload
    : undefined;
});

const isCreatingPointSample = computed(() => {
  return (
    drawingType.value === DrawingType.Point &&
    isMoving.value &&
    !isDuplicating.value
  );
});

const isEditingPointSample = computed(() => {
  if (!sampleDataToModify.value) {
    return false;
  }

  const layerModel = map.value!.getSampleLayerModel(sampleDataToModify.value);
  return (
    !!layerModel &&
    checkIsSampleGroup(layerModel) &&
    (isEditing.value || isDuplicating.value)
  );
});

const isIosAvailable = computed(() => {
  return (
    (hasAccess(FEATURES.GATHER_CHANGE_ICON_OPACITY) ?? false) &&
    (isCreatingPointSample.value || isEditingPointSample.value)
  );
});

const iosValue = computed(() => {
  iosInvalidationCounter.value;

  const defaultValue = 1;

  if (isMapMounted.value) {
    const allEditStates = map.value!.getInteractionManager().getAllEditStates();
    const [layerUid] = Object.keys(allEditStates);
    const [es] = layerUid ? allEditStates[layerUid] : [];
    if (es) {
      const { iconOpacity, iconOpacityOverride } = es;
      let value;
      if (isCreatingPointSample.value) {
        value = iconOpacity;
      } else if (isEditingPointSample.value) {
        value = iconOpacityOverride ?? iconOpacity;
      }
      value = parseFloat(value);
      return Number.isNaN(value) ? defaultValue : value;
    }
  }

  return defaultValue;
});

const isIosResetAvailable = computed(() => {
  iosInvalidationCounter.value;

  if (!isMapMounted.value) {
    return false;
  }

  const allEditStates = map.value!.getInteractionManager().getAllEditStates();
  const [layerUid] = Object.keys(allEditStates);
  const [es] = layerUid ? allEditStates[layerUid] : [];
  return es?.iconOpacityOverride != null;
});

const isModalStackEmpty = computed(() => legacyStore.getters.isModalStackEmpty);
const items = computed<Item[]>(() => sampleStore.samples as unknown as Item[]);

watch(
  isNonSpatialView,
  async (newValue) => {
    if (!newValue) {
      await waitFor(() => isMapMounted.value);
      if (isScaleVisible.value) {
        map.value!.addScaleLine();
      }
      map.value!.enableGeolocation();

      if (isOffline.value) {
        map.value!.setMinZoom(offlineZoomLevel.value);
      }
    }
  },
  { immediate: true }
);

watch(selectedFigure, (newValue) => {
  if (!newValue) {
    basemapId.value = null;
    return;
  }

  if (
    newValue.basemap_index === null ||
    !figureStore
      .getAvailableBasemapApis()
      .some((basemapApi) => basemapApi.id === newValue.basemap_index)
  ) {
    basemapId.value = BasemapId.OPEN_STREET_MAP;
  }
});

watch(basemapId, (newValue) => {
  if (typeof newValue === 'number') {
    map.value?.useBasemapApi(newValue);
  }
});

watch(isScaleVisible, (newValue) => {
  newValue ? map.value!.addScaleLine() : map.value!.removeScaleLine();
});

watch(isPoiEditingOn, (newValue) => {
  const im = map.value!.getInteractionManager();

  if (!im.hasSession()) {
    return;
  }

  const data = im.getData();
  const { layerModelId } = data;
  const layer = im.layerManager.findLayerByModelId(layerModelId);
  const feature = layer.getFirstFeature();

  // End existing session
  im.endSession();
  // Begin a new session
  im.beginSession(data);

  const edit = newValue
    ? im.requestPoiEdit(feature)
    : im.requestEdit(feature, false);
  edit.selectFeature(feature);
});

watch(modalStack, async (newValue, oldValue) => {
  const previousModal = getTopModal(oldValue);
  if (previousModal) {
    if (checkIsSampleModal(previousModal)) {
      sampleModal.value!.clear();
      showSampleModal.value = false;
    } else if (checkIsManagersModal(previousModal)) {
      showManagersModal.value = false;
    } else if (checkIsVersionControlModal(previousModal)) {
      sampleModal.value!.clear();
      showVersionControlModal.value = false;
    } else if (checkIsDeleteSampleModal(previousModal)) {
      sampleModal.value!.clear();
      showDeleteSampleModal.value = false;
    } else if (checkIsDeletePlainShapeModal(previousModal)) {
      sampleModal.value!.clear();
      layerModelIdToDelete.value = undefined;
    } else if (checkIsAlertUnsavedItemModal(previousModal)) {
      showAlertUnsavedItemModal.value = false;
    }

    await nextTick();
  }

  const currentModal = getTopModal(newValue);
  if (currentModal && checkIsAtTop(modalStack.value, currentModal)) {
    if (checkIsSampleModal(currentModal)) {
      const {
        project_id: projectId,
        project_number: projectNumber,
        project_name: projectName,
      } = project.value;
      updatePersistence({
        projectId,
        projectTitle: `${projectNumber} - ${projectName}`,
      });
      const { payload } = currentModal;
      if (typeof payload === 'number' || typeof payload === 'string') {
        const itemId = payload;
        const item = await sampleStore.findSampleByIdAsync(itemId);
        if (item) {
          EventBus.$emit('setSampleDataToModify', item);
          openSampleModal();
        } else {
          toastStore.error('The item is missing.');
        }
      } else if (typeof payload === 'function') {
        payload();
      }
    } else if (checkIsManagersModal(currentModal)) {
      showManagersModal.value = true;
    } else if (checkIsVersionControlModal(currentModal)) {
      showVersionControlModal.value = true;
    } else if (checkIsDeleteSampleModal(currentModal)) {
      showDeleteSampleModal.value = true;
    } else if (checkIsDeletePlainShapeModal(currentModal)) {
      layerModelIdToDelete.value = currentModal.payload;
    } else if (checkIsAlertUnsavedItemModal(currentModal)) {
      showAlertUnsavedItemModal.value = true;
    }
  }
});

watch(templateTabs, () => {
  isAppsLoaded.value = true;
});

const updatePersistence = (...args) =>
  legacyStore.dispatch('updatePersistence', ...args);
const setIsScaleVisible = (...args) =>
  legacyStore.dispatch('setIsScaleVisible', ...args);
const pushToModalStack = (...args) =>
  legacyStore.dispatch('pushToModalStack', ...args);
const popFromModalStack = (...args) =>
  legacyStore.dispatch('popFromModalStack', ...args);
const setHasUnsavedItem = (...args) =>
  legacyStore.dispatch('setHasUnsavedItem', ...args);

const invalidateIos = () => {
  iosInvalidationCounter.value++;
};

const handleMapMounted = () => {
  isMapMounted.value = true;
};

const handleMapUnmounted = () => {
  isMapMounted.value = false;
};

const handleFeatureClick = ({ feature, cursorPosition }) => {
  const sampleId = feature.getId();
  const sample = sampleId ? map.value!.findSampleById(sampleId) : undefined;

  let payload;
  if (sample) {
    // The area_figure _layer's styling could have been changed.
    if (sample.area_figure_layer) {
      const layerModel = map.value!.findLayerModelById(
        sample.area_figure_layer.id
      );
      sample.area_figure_layer = layerModel;
    }

    const clickedPoi = map.value!.getClickedPoi();
    if (clickedPoi && clickedPoi.sampleId === sample.id) {
      const poi = sample.points_of_interest[clickedPoi.index];
      const app = templateTabs.value.find(
        (app) => app.id === sample.template_tab_id
      );
      const section = app?.sections?.find(
        (section) => section.system_reference === 'point_of_interest'
      );
      const field = section?.template_fields?.find(
        (field) => field.system_reference === 'data_form'
      );
      const dataFormApp = templateTabs.value.find(
        (app) => app.title === field?.options?.template_tab_title
      );
      payload = () => {
        EventBus.$emit('setSampleDataToModify', {
          id: poi.dataForm,
          template_tab_id: dataFormApp?.id,
        });
        openSampleModal();
      };
    } else {
      payload = sample.id;
    }
  } else {
    payload = () => {
      const layerManager = map.value!.getLayerManager();
      const layer = layerManager.findLayerByFeature(feature);
      const layerUsage = getUsage(layer);

      if (
        [LayerUsageByType[LayerType.RECTANGLE].CACHING_BOUNDARY].includes(
          layerUsage
        )
      ) {
        return;
      }

      const layerModelId = map.value!.getLayerModelId(layer);
      const layerModel = map.value!.findLayerModelById(layerModelId);
      const latlng = map.value!.toLegacyLatLng(
        layerManager.getLayerCenter(layer)
      );
      EventBus.$emit('setSampleDataToModify', {
        latlng,
        geojson: {
          ...layerModel.geojson,
          properties: {
            ...layerModel.geojson.properties,
            layerId: layerModel.id,
          },
        },
      });
      openSampleModal();
    };
  }
  pushToModalStack({ type: StackableModalType.SampleModal, payload });
};

const handleDeleteSampleModalShow = () => {
  pushToModalStack({
    type: StackableModalType.DeleteSampleModal,
    payload: sampleDataToModify.value,
  });
};

const handleDeleteSampleModalClose = () => {
  popFromModalStack();
};

const handleDeletePlainShapeModalShow = (id) => {
  pushToModalStack({
    type: StackableModalType.DeletePlainShapeModal,
    payload: id,
  });
};

const handleDeletePlainShapeModalClose = () => {
  popFromModalStack();
};

const handleVersionControlModalShow = () => {
  pushToModalStack({
    type: StackableModalType.VersionControlModal,
    payload: sampleDataToModify.value,
  });
};

const handleVersionControlModalClose = () => {
  popFromModalStack();
};

const loadAllViewerEvents = () => {
  new ActionEvents(viewer);
  new SampleEvents(viewer);

  EventBus.$on('createNewSample', (sample) => {
    sampleStore.addSample(sample);
    EventBus.$emit('updateSample', sample);
  });

  EventBus.$on('viewImage', (url) => {
    showVersionControlModal.value = false;
    imageURL.value = url;
  });

  EventBus.$on('changeViewType', (_isNonSpatialView) => {
    changeViewType(_isNonSpatialView);
  });
};

const handleBasemapChange = (newBasemapId) => {
  basemapId.value = newBasemapId;
};

const checkGatherAccessRestriction = () => {
  const restrictions = useViewRestriction();
  if (!restrictions.hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER)) {
    goToApp({
      app: 'hub',
      project: project.value,
      route: 'project_dashboard',
    });
  }
};

const loadTemplate = async () => {
  if (!isOnline.value) {
    return;
  }

  try {
    const { data } = await axios.get(
      '/api/template/' + project.value.project_id
    );
    templateTabs.value = data.template_tabs;
  } catch (e) {
    toastStore.unexpected();
    throw e;
  }
};

const joinTeam = (role_id) => {
  api
    .joinTeam(role_id)
    .then(({ data }) => {
      const projectStore = useProjectStore();
      projectStore.updateProject({
        ...data.project,
        users: data.users,
      });

      checkGatherAccessRestriction();
    })
    .catch((err) => {
      throw err;
    });
};

const closeImageModal = () => {
  imageURL.value = null;
  showVersionControlModal.value = true;
};

const openSampleModal = () => {
  showSampleModal.value = true;
};

const closeSampleModal = (clear = false) => {
  if (clear) {
    popFromModalStack();
  } else {
    showSampleModal.value = false;
  }
};

const startNonSpatial = (param) => {
  pushToModalStack({
    type: StackableModalType.SampleModal,
    payload: () => {
      if (param) {
        templateTabToSelect.value = param.tabId;
      }
      EventBus.$emit('setSampleDataToModify', {});
      openSampleModal();
    },
  });
};

const setupOfflineProject = () => {
  const {
    template_tabs: _templateTabs = [],
    samples,
    offline_min_zoom: offlineMinZoom = 0,
  } = offlineProject.value!;

  templateTabs.value = _templateTabs;

  sampleStore.replaceSamples(
    samples.map((sample) => {
      // Fix the problem that type is missing
      if (
        sample.area_figure_layer &&
        !sample.area_figure_layer.geojson.properties.type
      ) {
        sample.area_figure_layer = createAreaFigureLayer(
          sample.area_geojson,
          sample.custom_title
        );
      }
      return sample;
    }) as any[]
  );

  offlineZoomLevel.value = offlineMinZoom;
};

const loadFromSession = async () => {
  const { projectId } = persistence.value;
  if (projectId && projectId !== project.value.project_id) {
    const { projectTitle, sampleIdentifier, inputValues } = persistence.value;
    let isAuthorized = true;
    const projectStore = useProjectStore();
    try {
      await projectStore.loadProjectDetails(projectId, false);
    } catch {
      isAuthorized = false;
    }
    pushToModalStack({
      type: StackableModalType.AlertUnsavedItemModal,
      payload: {
        projectId,
        projectTitle,
        unsavedItem: {
          title: sampleIdentifier,
          numberOfValues: inputValues.length,
        },
        isAuthorized,
      },
    });
    return;
  }

  const { sampleData } = persistence.value;
  if (sampleData) {
    setHasUnsavedItem(true);
    pushToModalStack({
      type: StackableModalType.SampleModal,
      payload: () => {
        EventBus.$emit('setSampleDataToModify', sampleData);
        openSampleModal();
      },
    });
  }
};

const changeViewType = async (_isNonSpatialView) => {
  isNonSpatialView.value = _isNonSpatialView;
  collectionStore.clearBusy();

  await nextTick();

  api.updateNonSpatialView({
    isNonSpatialView: isNonSpatialView.value,
  });

  const projectStore = useProjectStore();
  projectStore.updateProject({
    ...project.value,
    is_gather_non_spatial_view: isNonSpatialView.value,
  });
};

const updateField = async (fieldId, prop, value) => {
  if (!window.navigator.onLine) {
    return;
  }

  for (let i = 0; i < templateTabs.value.length; i++) {
    const app = templateTabs.value[i];
    for (let j = 0; j < (app.sections?.length ?? 0); j++) {
      const section = app.sections?.[j];
      for (let k = 0; k < (section?.template_fields?.length ?? 0); k++) {
        const field = section?.template_fields?.[k];
        if (field?.id === fieldId) {
          const nextField = Field.wrap({ ...field, [prop]: value });
          try {
            await nextField.save();
          } catch (e) {
            console.warn('Form was unable to update field');
          }
          section!.template_fields!.splice(k, 1, nextField as any);
        }
      }
    }
  }
};

const handleTrackingActivityModalStart = () => {
  handleTrackingActivityModalClose();
  EventBus.$emit('startTracking');
};

const handleTrackingActivityModalClose = () => {
  EventBus.$emit('closeTrackingActivityModal');
};

const handleScaleToggle = () => {
  setIsScaleVisible(!isScaleVisible.value);
};

const handleGeolocationEnabled = () => {
  EventBus.$emit('hasLocation', true);
};

const handleGeolocationDisabled = () => {
  EventBus.$emit('hasLocation', false);
};

const handleGeolocationError = () => {
  EventBus.$emit('hasLocation', false);
  toastStore.error(
    `The location service went wrong, please refresh the page to try it again.`
  );
};

const handlePoiAdd = (poi) => {
  sampleModal.value!.addPointOfInterest(poi);
};

const handlePoiLonLatChange = ({ index, lonLat }) => {
  sampleModal.value!.changeLonLatOfPointOfInterest(index, lonLat);
};

const handlePoiDelete = (index) => {
  sampleModal.value!.deletePointOfInterest(index);
};

const handlePoisChange = ({ sampleId, pois }) => {
  sampleStore.updateSample({
    id: sampleId,
    points_of_interest: pois,
  });

  const im = map.value!.getInteractionManager();
  if (im.hasSession()) {
    const layer = im.layerManager.findSampleLayerBySampleId(sampleId);
    const edit = map.value!.getInteractionManager().getEdit(layer);
    if (typeof edit.updatePoiFeatures === 'function') {
      edit.updatePoiFeatures();
    }
  }
};

const handleSampleLoaded = () => {
  sampleModal.value!.saveToSession();
};

const handleManagersModalClose = () => {
  popFromModalStack();
};

onMounted(async () => {
  checkGatherAccessRestriction();
  loadAllViewerEvents();
  await gatherSchema.loadPhases(project.value.project_id);
  await loadTemplate();
  await loadFromSession();
  if (isOffline.value) {
    setupOfflineProject();
  }
});

onBeforeUnmount(() => {
  [
    ...ActionEvents.eventNames,
    ...SampleEvents.eventNames,
    'createNewSample',
    'viewImage',
    'changeViewType',
  ].forEach((eventName) => EventBus.$off(eventName));

  LoadManager.destroy();
});

isNonSpatialView.value = project.value.is_gather_non_spatial_view == true;

const viewer = {
  get project() {
    return project.value;
  },
  get map() {
    return map.value;
  },
  getMap() {
    return map.value;
  },

  get toastStore() {
    return toastStore;
  },
  get sampleStore() {
    return sampleStore;
  },

  get isDrawing() {
    return isDrawing.value;
  },
  set isDrawing(value) {
    isDrawing.value = value;
  },
  get isMoving() {
    return isMoving.value;
  },
  set isMoving(value) {
    isMoving.value = value;
  },
  get isEditing() {
    return isEditing.value;
  },
  set isEditing(value) {
    isEditing.value = value;
  },
  get isDuplicating() {
    return isDuplicating.value;
  },
  set isDuplicating(value) {
    isDuplicating.value = value;
  },
  get isTracking() {
    return isTracking.value;
  },
  set isTracking(value) {
    isTracking.value = value;
  },
  get isTrackingAvailable() {
    return isTrackingAvailable.value;
  },
  set isTrackingAvailable(value) {
    isTrackingAvailable.value = value;
  },
  get showTrackingActivityModal() {
    return showTrackingActivityModal.value;
  },
  set showTrackingActivityModal(value) {
    showTrackingActivityModal.value = value;
  },
  get isAllowCollectionOnPoiAvailable() {
    return isAllowCollectionOnPoiAvailable.value;
  },
  get isPoiEditingOn() {
    return isPoiEditingOn.value;
  },
  set isPoiEditingOn(value) {
    isPoiEditingOn.value = value;
  },
  get drawingType() {
    return drawingType.value;
  },
  set drawingType(value) {
    drawingType.value = value;
  },
  get nonSpatialEditor() {
    return nonSpatialEditor.value;
  },
  get layerStylingOffcanvas() {
    return layerStylingOffcanvas.value;
  },
  get sampleModal() {
    return sampleModal.value;
  },
  get sampleDataToModify() {
    return sampleDataToModify.value;
  },
  set sampleDataToModify(value) {
    sampleDataToModify.value = value;
  },
  get modalStack() {
    return modalStack.value;
  },
  get isModalStackEmpty() {
    return isModalStackEmpty.value;
  },
  openSampleModal,
  closeSampleModal,
  pushToModalStack,
  popFromModalStack,

  get templateTabs() {
    return templateTabs.value;
  },
  get templateTabToSelect() {
    return templateTabToSelect.value;
  },
  set templateTabToSelect(value) {
    templateTabToSelect.value = value;
  },

  get isNonSpatialView() {
    return isNonSpatialView.value;
  },
  get isCreatingPointSample() {
    return isCreatingPointSample.value;
  },
  get isEditingPointSample() {
    return isEditingPointSample.value;
  },
  startNonSpatial,
  invalidateIos,
  updatePersistence,
};
</script>

<template>
  <div style="display: contents; max-width: 100%; max-height: 100%">
    <ViewHeader v-if="!isNonSpatialView" :project="project" />

    <NonSpatialEditor
      v-if="isNonSpatialView"
      ref="nonSpatialEditor"
      :templateTabs="templateTabs"
      class="mt-3"
      @showProjectInfoModal="() => (showProjectInfoModal = true)"
      @showDeleteSampleModal="handleDeleteSampleModalShow"
      @showVersionControlModal="handleVersionControlModalShow"
      @startNonSpatial="startNonSpatial"
      @changeViewType="changeViewType"
    />

    <div v-if="!isNonSpatialView" class="flex-grow-1 d-flex flex-column">
      <IndeterminateProgressBar v-if="collectionStore.isBusy" />

      <Map
        ref="map"
        class="flex-grow-1"
        :center="figureCenter"
        :basemapId="basemapId"
        :apps="templateTabs"
        :isAppsLoaded="isAppsLoaded"
        :projection="projection"
        @mounted="handleMapMounted"
        @unmounted="handleMapUnmounted"
        @featureClick="handleFeatureClick"
        @geolocationEnabled="handleGeolocationEnabled"
        @geolocationDisabled="handleGeolocationDisabled"
        @geolocationError="handleGeolocationError"
        @poiAdd="handlePoiAdd"
        @poiLonlatChange="handlePoiLonLatChange"
        @poiDelete="handlePoiDelete"
      />
    </div>

    <template v-if="project">
      <MapActionMenu
        v-if="!isNonSpatialView"
        :isModifying="isMoving || isDrawing || isEditing || isDuplicating"
        :templateTabs="templateTabs"
        :samples="items"
        :isTrackingAvailable="isTrackingAvailable"
        :drawingType="drawingType"
        :isAllowCollectionOnPoiAvailable="isAllowCollectionOnPoiAvailable"
        :isPoiEditingOn="isPoiEditingOn"
        :selectedBasemapId="basemapId"
        :isIosAvailable="isIosAvailable"
        :iosValue="iosValue"
        :isIosResetAvailable="isIosResetAvailable"
        @showProjectInfoModal="() => (showProjectInfoModal = true)"
        @showAddressLookupModal="() => (showAddressLookupModal = true)"
        @showOfflineManagerModal="() => (showOfflineManagerModal = true)"
        @scaleToggle="handleScaleToggle"
        @basemapChange="handleBasemapChange"
      />

      <SampleModal
        ref="sampleModal"
        v-model="showSampleModal"
        v-model:sampleData="sampleDataToModify"
        :templateTabs="templateTabs"
        :isNonSpatialView="isNonSpatialView"
        :updateField="updateField"
        :isAllowCollectionOnPoiAvailable="isAllowCollectionOnPoiAvailable"
        :isEditing="isEditing"
        @close="closeSampleModal"
        @open="openSampleModal"
        @showDeleteSampleModal="handleDeleteSampleModalShow"
        @showDeletePlainShapeModal="handleDeletePlainShapeModalShow"
        @poisChange="handlePoisChange"
        @sampleLoaded="handleSampleLoaded"
      />

      <ProjectInfoModal
        :show="showProjectInfoModal"
        @close="() => (showProjectInfoModal = false)"
      />

      <ManagersModal
        v-if="showManagersModal && templateTabs"
        :items="items"
        :apps="templateTabs"
        @showVersionControlModal="handleVersionControlModalShow"
        @close="handleManagersModalClose"
      />

      <NotifyModal
        v-if="showDeleteSampleModal"
        headerMessage="Are you sure you want to delete this item?"
        :isDelete="true"
        @close="handleDeleteSampleModalClose"
        @submit="EventBus.$emit('deleteSample')"
      />

      <NotifyModal
        v-if="layerModelIdToDelete"
        headerMessage="Are you sure you want to delete this plain shape?"
        :isDelete="true"
        @close="handleDeletePlainShapeModalClose"
        @submit="EventBus.$emit('deletePlainShapeConfirmed')"
      />

      <AlertUnsavedItemModal
        v-if="showAlertUnsavedItemModal && alertUnsavedItemModalPayload"
        :payload="alertUnsavedItemModalPayload"
      />

      <UnauthorizedModal
        v-if="!userAuthorized"
        :users="project.users || []"
        @joinTeam="joinTeam"
      />

      <AddressLookupModal
        :show="showAddressLookupModal"
        :map="map"
        @close="() => (showAddressLookupModal = false)"
      />

      <VersionControlModal
        :show="showVersionControlModal"
        :sample="sampleDataToModify"
        :isNonSpatialView="isNonSpatialView"
        @close="handleVersionControlModalClose"
      />

      <OfflineManagementModal
        v-if="showOfflineManagerModal"
        :selectedProject="project"
        :selectedFigureId="selectedFigure?.id || null"
        @close="showOfflineManagerModal = false"
      />

      <ImageModal
        v-if="getImageURL"
        :imageUrl="getImageURL"
        @close="closeImageModal"
      />

      <TrackingActivityModal
        v-if="showTrackingActivityModal"
        @start="handleTrackingActivityModalStart"
        @close="handleTrackingActivityModalClose"
      />

      <LayerStylingOffcanvas v-if="isMapMounted" ref="layerStylingOffcanvas" />
    </template>
  </div>
</template>
