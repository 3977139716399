<script lang="ts" setup>
import { ref, Teleport } from 'vue';
import { Project } from '../../project';
import TabLogProjectMatcherModal from './TabLogProjectMatcherModal.vue';
import ButtonSpinner from '../../components/ButtonSpinner.vue';
import AlertBox from '../../components/AlertBox.vue';

defineProps<{
  project: Project;
}>();
const showProjectMatcher = ref(false);

function openProjectMatcher() {
  showProjectMatcher.value = true;
}
</script>

<template>
  <div>
    <div v-if="!project.tab_logs_project_number">
      <AlertBox type="info" class="mb-3">
        Please match this project with a Tab Logs project.
      </AlertBox>

      <ButtonSpinner
        class="mb-3 btn btn-primary flex-grow-1 w-100"
        @click="openProjectMatcher"
        >Match TabLogs Project</ButtonSpinner
      >
    </div>
    <Teleport to="body">
      <TabLogProjectMatcherModal
        v-if="showProjectMatcher"
        @close="showProjectMatcher = false"
      />
    </Teleport>
  </div>
</template>
