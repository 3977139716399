import { FieldTypeIds } from '@component-library/fields';

export default {
  getTemplateById: (templateKey) => {
    const templates = {
      sample_matrix: {
        label: 'Lab Sample (Soil, Water, Sediment)',
        is_repeatable: false,
        fields: [
          {
            label: 'Depth',
            field_type_id: FieldTypeIds.DEPTH,
            is_permanent: true,
            options: {
              is_range: true,
            },
          },
          {
            label: 'Lab ID',
            field_type_id: FieldTypeIds.LAB_ID,
            is_permanent: true,
            options: {
              selected_lab_title_format_id: 'default-structure-1',
            },
          },
          {
            label: 'Matrix',
            field_type_id: FieldTypeIds.DROPDOWN,
            is_permanent: true,
            options: {
              options: ['soil', 'water', 'soilgas', 'leachate'],
            },
          },
          {
            label: 'Sampled Date',
            field_type_id: FieldTypeIds.DATE,
            is_permanent: true,
            options: {
              type: 'date',
              format: 'DDMMYY',
            },
          },
          {
            label: 'Type of soil (TOPSOIL, FILL, etc)',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {
              conditions: [
                {
                  field_label: 'Matrix',
                  field_id: null,
                  operator: '=',
                  value: 'soil',
                },
              ],
            },
            system_reference: 'description_of_soil',
          },
          {
            label: 'Type of soil (e.g. Sand, Clay, Silt)',
            field_type_id: FieldTypeIds.DROPDOWN,
            is_permanent: true,
            options: {
              conditions: [
                {
                  field_label: 'Matrix',
                  field_id: null,
                  operator: '=',
                  value: 'soil',
                },
              ],
              options: ['Clay', 'Sand', 'Silt'],
              option_type: 1,
              has_multiple: true,
              is_add_enabled: true,
            },
            system_reference: 'enviro_type_of_soil',
          },
          {
            label: 'Is a duplicate sample collected?',
            field_type_id: FieldTypeIds.CHECKBOX,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Duplicate ID',
            field_type_id: FieldTypeIds.DUPLICATE,
            is_permanent: true,
            options: {
              conditions: [
                {
                  field_label: 'Is a duplicate sample collected?',
                  field_id: null,
                  operator: '=',
                  value: 'yes',
                },
              ],
              rpd_allow_custom_title: true,
            },
            system_reference: 'duplicate_id',
          },
          {
            label: 'Is a triplicate sample collected?',
            field_type_id: FieldTypeIds.CHECKBOX,
            is_permanent: true,
            options: {
              conditions: [
                {
                  field_label: 'Is a duplicate sample collected?',
                  field_id: null,
                  operator: '=',
                  value: 'yes',
                },
              ],
            },
          },
          {
            label: 'Triplicate ID',
            field_type_id: FieldTypeIds.TRIPLICATE,
            is_permanent: true,
            options: {
              conditions: [
                {
                  field_label: 'Is a triplicate sample collected?',
                  field_id: null,
                  operator: '=',
                  value: 'yes',
                },
              ],
              rpd_allow_custom_title: true,
            },
            system_reference: 'triplicate_id',
          },
        ],
      },
      sample_lab: {
        label: 'Lab Sample',
        is_repeatable: false,
        fields: [
          {
            label: 'Sample collected',
            field_type_id: FieldTypeIds.CHECKBOX,
            is_permanent: true,
            options: {},
          },
        ],
      },
      health_safety: {
        label: 'Health & Safety',
        is_repeatable: true,
        is_permanent: true,
        is_health_safety: true,
        fields: [
          {
            label: 'Category',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Hazard',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Pre-Risk Score',
            field_type_id: FieldTypeIds.NUMBER,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Control Measure',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Consequence',
            field_type_id: FieldTypeIds.DROPDOWN,
            is_permanent: true,
            options: {
              options: [
                'Negligible',
                'Minor',
                'Moderate',
                'Major',
                'Catastrophic',
              ],
            },
          },
          {
            label: 'Likelihood',
            field_type_id: FieldTypeIds.DROPDOWN,
            is_permanent: true,
            options: {
              options: [
                'Rare',
                'Unlikely',
                'Possible',
                'Likely',
                'Almost Certain',
              ],
            },
          },
          {
            label: 'Post-Risk Score',
            field_type_id: FieldTypeIds.NUMBER,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Person Responsibility',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
        ],
      },
      soil_log: {
        label: 'Soil Log',
        fields: [
          {
            label: 'Depth',
            field_type_id: FieldTypeIds.DEPTH,
            is_permanent: true,
            options: {
              is_range: true,
            },
          },
          {
            label: 'Description',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
        ],
      },
      site_visit: {
        label: 'Site Visit',
        fields: [
          {
            label: 'Date / Time',
            field_type_id: FieldTypeIds.DATE,
            is_permanent: true,
            options: {},
          },
        ],
      },
      photolog: {
        label: 'Photolog',
        fields: [
          {
            label: 'Photo',
            field_type_id: FieldTypeIds.MEDIA,
            is_permanent: true,
            options: {},
          },
          {
            label: 'Description',
            field_type_id: FieldTypeIds.TEXT,
            is_permanent: true,
            options: {},
          },
        ],
      },
      gps_point_metadata: {
        label: 'GPS Point Metadata',
        fields: [
          {
            label: 'Pole Height',
            field_type_id: FieldTypeIds.NUMBER,
            system_reference: 'pole_height',
            is_permanent: true,
            options: { unit: 'Metre' },
          },
          {
            label: 'Phase Centre Offset',
            field_type_id: FieldTypeIds.NUMBER,
            system_reference: 'phase_center_offset',
            is_permanent: true,
            options: { unit: 'Metre' },
          },
          {
            label: 'Latitude',
            field_type_id: FieldTypeIds.NUMBER,
            system_reference: 'latitude',
            is_permanent: true,
            options: { is_readonly: true, unit: 'DD' },
          },
          {
            label: 'Longitude',
            field_type_id: FieldTypeIds.NUMBER,
            system_reference: 'longitude',
            is_permanent: true,
            options: { is_readonly: true, unit: 'DD' },
          },
          {
            label: 'Elevation',
            field_type_id: FieldTypeIds.NUMBER,
            system_reference: 'elevation',
            is_permanent: true,
            options: { is_readonly: true, unit: 'Metre' },
          },
          {
            label: 'Corrected Elevation',
            field_type_id: FieldTypeIds.EXPRESSION,
            system_reference: 'corrected_elevation',
            is_permanent: true,
            options: {
              unit: 'Metre',
              expression:
                '[elevation] - ( [pole_height] + [phase_center_offset] )',
              maxDecimals: 3,
            },
          },
          {
            label: 'Timestamp',
            field_type_id: FieldTypeIds.TEXT,
            system_reference: 'timestamp',
            is_permanent: true,
            options: { is_readonly: true },
          },
        ],
      },
    };

    return templates[templateKey];
  },
};
