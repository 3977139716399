import { ucFirstEachWord } from '../../utils';
import { App, GatherField, ItemDetailed, normalizeDate } from '../../gather';
import { User } from "../../user";
import { Project } from "../../project";
import moment from 'moment';
import { useToastStore } from '../../store/toasts';
import useApi from '../../api';
import { defineAsyncComponent } from 'vue';

/**
 * Needs to be matched in hub/config/services.php tab-logs
 */
export const TL_APP_TITLE_KEYWORDS = [
    'tablog',
    'soil logging - senversa',
] as readonly string[];

export function isAppTabLogs(app: App) {
    return TL_APP_TITLE_KEYWORDS.some((keyword) => app.title?.toLowerCase().includes(keyword));
}

export type TabLogProject = {
    id: number;
    external_id: number | null;
    project_number: string;
    project_name: string;
    location: string;
    client_id: number;
    client_name: string;
    latitude: string;
    longitude: string;
    is_archived: number;
    scheduled_drilling_start_date: string;
    scheduled_drilling_finish_date: string;
    project_due_date: string;
    project_team: number[];
    logs_info: TabLogLog[] | null;
};

export type TabLogLog = any;

export type TabLogInputSettings = {
    component?: any;
    type?: string;
    key: string;
    label?: string;
    always?: boolean;
    disabled?: boolean;
    note?: string;
    matchedField?: GatherField;
    condition?: (item: ItemDetailed, section: Record<string, string>) => boolean;
    col?: 'col' | 'col-4' | 'col-6' | 'col-12';
    placeholder?: string | number | null;
};

export const tabLogTabs = {
    general_info: 'General Info',
    subsurface: 'Subsurface',
    comments: 'Comments',
    lab_samples: 'Lab Samples',
    waterstrike: 'Water Strike',
    hydrocarbon: 'Hydrocarbon',
    end_depth_comments: 'End Depth',
    well_construction: 'Well Construction',
    well_backfill: 'Well Backfill',
    drilling_methods: 'Drilling Methods',
} as const;

export type InputMap = {
    [key in keyof typeof tabLogTabs]: TabLogInputSettings[];
};

export const tabLogInputMap: InputMap = {
    general_info: [
        {
            key: 'tablogs_template',
            label: 'TabLogs Template',
            component: defineAsyncComponent(() => import('../../components/InputSelect.vue')),
            placeholder: 'Default Branded Template',
            note: 'Contact Datanest for TabLogs customization options',
        },
        {
            key: 'depth_per_page',
            label: 'Depth per page',
            type: 'number',
            placeholder: '4',
        },
        {
            key: 'logged_by',
            label: 'Logged By',
        },
        {
            key: 'reviewed_by',
            label: 'Reviewed By',
        },
        {
            type: 'date',
            key: 'start',
            label: 'Start Date',
        },
        {
            type: 'time',
            key: 'start_time',
            label: 'Start Time',
            col: 'col-6',
        },
        {
            type: 'date',
            key: 'end',
            label: 'End Date',
        },
        {
            type: 'time',
            key: 'end_time',
            label: 'End Time',
        },
        { key: 'log_type' },
        {
            key: 'elevation_m',
        },
        {
            key: 'borehole_diameter',
        },
        {
            key: 'supplier_name',
        },
        {
            key: 'excavator_name',
        },
        {
            key: 'location_comments',
        },
    ],
    subsurface: [
        {
            key: 'depth',
            label: 'Start Depth',
            type: 'number',
        },
        {
            type: 'number',
            always: true,
            key: 'depth_end',
            label: 'End Depth',
        },
        {
            key: 'origin_type',
            label: 'Origin Type',
        },
        {
            key: 'pavement_type',
            label: 'Pavement Type',
            condition: (item: ItemDetailed, section: Record<string, string>) =>
                section?.origin_type === 'Non-Soil',
        },
        {
            key: 'rock_type',
            label: 'Rock Type',
            condition: (item: ItemDetailed, section: Record<string, string>) => {
                return section?.origin_type === 'Rock';
            },
        },
        {
            key: 'soil_type',
            label: 'Soil Type',
            condition: (item: ItemDetailed, section: Record<string, string>) =>
                section?.origin_type === 'Soil',

        },
        {
            key: 'origin',
            label: 'Origin',
        },
        {
            key: 'classification_for_tablogs',
        },
        {
            key: 'moisture',
        },
        {
            key: 'line_type',
        },
        {
            key: 'soil_description',
            label: 'Soil Description',
            col: 'col-12',
        },
    ],
    comments: [
        {
            key: 'depth',
            label: 'Start Depth (m)',
            type: 'number',
            col: 'col-12',
        },
        {
            key: 'depth_end',
            always: true,
            label: 'End Depth (m)',
            type: 'number',
            col: 'col-12',
        },
        {
            key: 'comment',
            label: 'Comment',
            col: 'col-12',
        },
    ],
    lab_samples: [
        {
            key: 'depth',
            label: 'Start Depth (m)',
            type: 'number',
        },
        {
            key: 'depth_end',
            always: true,
            label: 'End Depth (m)',
            type: 'number',
        },
        {
            key: 'sample_number',
            label: 'Sample Number',
        },
        {
            key: 'sample_type',
            label: 'Sample Type',
        },
        { key: 'comments', label: 'Comments' },
        {
            key: 'matrix',
            label: 'Matrix',
        },
        {
            key: 'sample_concatenation',
            label: 'Sample Description (Concatenation)',
        },
    ],
    waterstrike: [
        {
            key: 'depth',
            label: 'Depth (m)',
        }, {
            key: 'type',
        },
        {
            type: 'date',
            key: 'date_time',
            label: 'Date',
        },
        {
            type: 'time',
            key: 'date_time_time',
            label: 'Time',
        },
        {
            key: 'weather_conditions',
            label: 'Weather Conditions',
        },
    ],
    end_depth_comments: [
        {
            key: 'final_depth',
            label: 'Final Depth (m)',
        },
        {
            key: 'end_depth_comments_for_the_base_of_log',
            label: 'End Depth Comments',
        },
    ],
    hydrocarbon: [
        {
            key: 'depth',
            label: 'Depth (m)',
        },
        {
            key: 'hydrocarbon_screening',
            label: 'Hydrocarbon Screening',
        },
    ],
    well_construction: [
        {
            key: 'depth',
            label: 'Start Depth (m)',
        },
        {
            key: 'depth_end',
            always: true,
            label: 'End Depth (m)',
        },
        {
            key: 'well_id',
        },
        {
            key: 'pipe_type',
        },
    ],
    well_backfill: [
        {
            key: 'depth',
            always: true,
            label: 'Start Depth (m)',
        },
        {
            key: 'depth_end',
            label: 'End Depth (m)',
            always: true,
        },
        {
            key: 'backfill_type',
        },
    ],
    drilling_methods: [
        {
            key: 'depth',
            label: 'Start Depth (m)',
        },
        {
            key: 'depth_end',
            label: 'End Depth (m)',
            always: true,
        },
        {
            key: 'method',
        },
        {
            key: 'comments',
        },
    ],
} as const;

export function remapData(authUser: User, project: Project, itemData: ItemDetailed) {
    const generalInfo = (itemData.general_info ?? {}) as Record<string, string>;
    let contact_phone = authUser?.phone_number;
    if (contact_phone && authUser?.phone_prefix) {
        contact_phone = authUser.phone_prefix + contact_phone;
    }
    return {
        contact_name: generalInfo?.logged_by,
        contact_email: authUser?.email,
        contact_phone,
        project_number: project?.project_number,
        project_name: project?.project_name,
        client_company: project?.project_client,
        location: project?.project_address,
        latitude: project?.latitude,
        longitude: project?.longitude,
        log_configuration: generalInfo?.tablogs_template,
        depth_per_page: generalInfo?.depth_per_page && generalInfo.depth_per_page !== "" ? generalInfo.depth_per_page : 4,
        logs_info: [remapSoilLogData(itemData)],
    };
}

function normalizeSectionAsRepeatable(section?: Record<string, any> | Record<string, any>[]): Record<string, any>[] {
    if (!section) {
        return [];
    }
    return Array.isArray(section) ? section : [section];
}

function normalizeSectionAsSingle(section?: Record<string, any> | Record<string, any>[]): Record<string, any> {
    if (!section) {
        return {};
    }
    return Array.isArray(section) ? section[0] : section;
}

enum TabLogsLogType {
    Borehole = 1,
    Testpit = 2,
}

function remapSoilLogData(itemData: ItemDetailed) {
    const generalInfo = normalizeSectionAsSingle((itemData.general_info ?? {}) as Record<string, string>);
    const end_depth_comments = normalizeSectionAsSingle((itemData.end_depth_comments ?? {}) as Record<
        string,
        string
    >);
    const subsurfaces = normalizeSectionAsRepeatable((itemData.subsurface ?? []) as Record<string, string>[]);
    const comments = normalizeSectionAsRepeatable((itemData.comments ?? []) as Record<string, string>[]);
    const samples = normalizeSectionAsRepeatable((itemData.lab_samples ?? []) as Record<string, string>[]);
    const hydrocarbons = normalizeSectionAsRepeatable((itemData.hydrocarbon ?? []) as Record<string, string>[]);
    const waterObservations = normalizeSectionAsRepeatable((itemData.waterstrike ?? []) as Record<
        string,
        string
    >[]);
    const wellConstruction = normalizeSectionAsRepeatable((itemData.well_construction ?? []) as Record<
        string,
        string>[]);
    const wellBackfill = normalizeSectionAsRepeatable((itemData.well_backfill ?? []) as Record<
        string,
        string>[]);
    const drillingMethods = normalizeSectionAsRepeatable((itemData.drilling_methods ?? []) as Record<
        string,
        string>[]);


    const startDate = generalInfo.start ? normalizeDate(generalInfo.start) : undefined;
    const endDate = generalInfo.end ? normalizeDate(generalInfo.end) : undefined;

    return {
        log_type: (generalInfo.log_type + "").toLowerCase() === 'testpit' ? TabLogsLogType.Testpit : TabLogsLogType.Borehole,
        log_no: itemData.title,
        start_date: startDate ? moment(startDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
        completed_date: endDate ? moment(endDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
        easting: itemData.latitude || 0,
        northing: itemData.longitude || 0,
        contact_name: generalInfo.logged_by,
        drill_rig: generalInfo.excavator_name,
        drill_rig_supplier: generalInfo.supplier_name,
        end_depth: end_depth_comments.final_depth,
        latitude: itemData.latitude || 0,
        longitude: itemData.longitude || 0,
        end_depth_comments:
            end_depth_comments.end_depth_comments_for_the_base_of_log,
        general_comments: generalInfo.general_comments,
        location: generalInfo.location_comments,
        logged_by: generalInfo.logged_by?.length ? generalInfo.logged_by : ' ',
        reviewed_by: generalInfo.reviewed_by?.length ? generalInfo.reviewed_by : ' ',
        elevation: parseFloat(generalInfo.elevation_m),

        well_logs: wellConstruction.map((well) => {
            return {
                depth_from: well.depth,
                depth_to: well.depth_end,
                well_id: well.well_id,
                well_type: well.pipe_type,
            };
        }),
        well_backfill_logs: wellBackfill.filter(w => w.backfill_type).map((well) => {
            return {
                depth_from: well.depth,
                depth_to: well.depth_end,
                type: well.backfill_type,
            };
        }),

        sub_surfaces_data: subsurfaces.map((subsurface) => {
            return {
                // TabLogs bug: hack to prevent 0 being treated as empty.
                depth: subsurface.depth,
                origin_type: subsurface.origin_type,
                origin: subsurface.origin,
                classification_code: subsurface.classification_for_tablogs,
                soil_type: subsurface.soil_type?.toLowerCase() || '',
                rock_type: subsurface.rock_type?.toLowerCase() || '',
                pavement_type: subsurface.pavement_type?.toLowerCase() || '',
                moisture: subsurface.moisture
                    ? ucFirstEachWord(subsurface.moisture.toLowerCase())
                    : undefined,
                weathering: [],
                decomposition: null,
                description: subsurface.soil_description,
                line_type: subsurface.line_type,
            };
        }),
        log_remarks: comments.filter(comment => comment.comment && String(comment.comment).trim().length).map((comment) => {
            return {
                depth_from: comment.depth,
                depth_to: comment.depth_end,
                type: 'note',
                remarks: comment.comment.trim(),
            };
        }),
        water_observation_data: remapWaterObservations(waterObservations),
        sample_data: [
            ...samples.map(s => ({
                depth_from: s.depth,
                depth_to: s.depth_end,
                type: s.sample_type || 'Soil',
                comments: s.comments, // Requires update from TabLogs to support in their API, the web UI supports it.
                sample_id: s.sample_concatenation,
            })),
        ],
        in_situ_data: [{
            type: 'PID',
            data: remapHydrocarbons(hydrocarbons),
        }],

        drilling_method_data: remapDrillingMethods(drillingMethods),
    }
}

function remapDrillingMethods(drillingMethods: Record<string, string>[]) {
    return drillingMethods.map((drillingMethod) => {
        return {
            depth_from: drillingMethod.depth,
            depth_to: drillingMethod.depth_end,
            type: drillingMethod.method,
            comments: drillingMethod.comments,
        };
    });
}

function remapWaterObservations(waterObservations: Record<string, string>[]) {
    return waterObservations.map((waterObservation) => {
        return {
            depth: waterObservation.depth,
            observation_type: waterObservation.type || 'In',
            observation_time: waterObservation.date_time && waterObservation.date_time ? moment(waterObservation.date_time + ' ' + waterObservation.date_time_time).format('Z') : undefined,
            comments: waterObservation.weather_conditions,
        };
    });
}

function remapHydrocarbons(
    hydrocarbons: Record<string, string>[],
) {
    return hydrocarbons.map((hydrocarbon) => ({
        depth_from: hydrocarbon.depth,
        depth_to: hydrocarbon.depth,
        value: hydrocarbon.hydrocarbon_screening,
    }));
}

export enum TabLogsExportState {
    /** Not a DB state, pending while API request is in flight **/
    PENDING = -1,
    INCOMPLETE = 0,
    EXPORTING = 1,
    EXPORTED = 2,
    FAILED = 3,
    NOT_READY = 4,
    READY = 5,
}

export type TabLogsExportItem = {
    item_id: number;
    tab_log_id: number;
    state: TabLogsExportState;
    item: ItemDetailed;
    error: string | null;
};

type PreviewResponse = TabLogsExportItem & {
    checksum: string;
    preview_url: string | null;
    tab_logs_url: string | null;
};

export function sendPreviewRequest(
    projectId: number,
    itemId: number,
    remappedData: any
): Promise<PreviewResponse> {
    remappedData['project_id'] = projectId;
    return useApi()
        .post('gather/items/tab-logs/' + itemId + '/preview', remappedData)
        .then((response) => {
            return response.data;
        });
}

export async function loadTabLogItem(
    projectId: number,
    itemId: number
): Promise<ItemDetailed> {
    try {
        // Load tab log items
        const response = await useApi().get('gather/items/tab-logs/' + itemId, {
            params: { with_linked_spatial: 1, project_id: projectId },
        });
        return response.data.data;
    } catch (error) {
        useToastStore().error('Failed to load Gather items');
        throw error;
    }
}

