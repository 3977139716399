<template>
  <div v-click-outside="hideContent" class="form-group position-relative">
    <label class="form-label" :for="displayId"> Sub Folder </label>
    <div class="input-group">
      <input
        :id="displayId"
        ref="display"
        type="text"
        class="form-control bg-white cursor-pointer"
        :value="value"
        autocomplete="off"
        @focus="onDisplayFocus"
        @click.prevent="onDisplayClick"
      />
      <button
        class="btn btn-danger"
        type="button"
        @click.prevent="setValue(null)"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div v-if="isContentVisible" class="dropdown-content">
      <input
        v-if="isSearchable"
        v-model="search"
        type="text"
        class="form-control border-0"
        placeholder="Type to search sub folders..."
      />
      <a
        v-for="(item, index) in searchResult"
        :key="`subFolder_${index}`"
        @click.prevent="setValue(item)"
        >{{ item }}</a
      >
      <a v-if="!searchResult.length" class="text-muted">
        No sub folders for "{{ search }}"
      </a>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'SubFolderDropdown',
  props: {
    id: {
      type: String,
      required: true,
    },
    subFolders: {
      type: Array,
      required: true,
    },
    value: String,
  },
  data: () => ({
    search: null,
    isContentVisible: false,
  }),
  computed: {
    displayId() {
      return `${this.id}_display`;
    },
    isSearchable() {
      return this.subFolders.length > 5;
    },
    searchDebounced: {
      get() {
        return this.search;
      },
      set: _debounce(function (value) {
        this.search = value;
      }, 500),
    },
    searchResult() {
      return this.search
        ? this.subFolders.filter((item) =>
            item.toLowerCase().includes(this.search.toLowerCase())
          )
        : [...this.subFolders];
    },
  },
  methods: {
    onDisplayFocus() {
      this.$refs.display.blur();
    },
    onDisplayClick() {
      this.isContentVisible = !this.isContentVisible;
    },
    hideContent() {
      this.isContentVisible = false;
    },
    setValue(value) {
      this.search = null;
      this.hideContent();
      this.$emit('subFolderChanged', value);
    },
  },
};
</script>

<style scoped>
/* Dropdown Content*/
.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 25rem;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #000 !important;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  color: #000 !important;
}
</style>
