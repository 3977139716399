import type { ChemicalResult, Scenario } from '../evalu8/types';
import type { Country, Pid } from '../types';
import type { Figure as CommonFigure } from '../../../../component-library/maps';

export type Project = {
  project_id: number;
  project_type: ProjectType;
  measurement_type: 'metre' | 'mm' | 'cm' | 'feet' | 'inch';
  location: { country: Country; state: string };
  address_state: string;
  longitude: number;
  latitude: number;
};

export enum ProjectType {
  ENVIRO = 0,
  STANDARD = 1,
}

export type Figure = CommonFigure & {
  id: number;
  title: string;
  scenarios: Scenario[];
  // an array of chemical ids
  chemicals: number[];
  results?: ChemicalResult[];
  hide_sample_labels: boolean;
  hide_sample_exceedance_styling: boolean;
  enviro_callout_filter: {
    hiddenFormattedDepths?: (string | null)[];
  };
  gather_access: boolean;
  view_lng: number;
  view_lat: number;
  is_basemap: boolean;
  locked_layer_ids?: Pid[];
  basemap_index: number | null;
  view_projection?: string;
};
