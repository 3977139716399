<script setup>
import { computed, onBeforeMount, ref } from 'vue';

import makeId from '../local-id.mjs';

const props = defineProps({
  label: String,
  placeholder: String,
  name: String,
  modelValue: String | Number,
  value: String | Number,
  errorBag: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
  isDatalist: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'input']);

const id = ref(props.name);

onBeforeMount(updateId);

const inputError = computed(() => {
  return props.name in props.errorBag
    ? Array.isArray(props.errorBag[props.name])
      ? props.errorBag[props.name][0]
      : props.errorBag[props.name]
    : null;
});

const inputValue = computed({
  get: () => {
    return props.modelValue ?? props.value ?? null;
  },
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}
</script>

<template>
  <div>
    <div class="form-floating">
      <template v-if="isDatalist">
        <input
          :id="id"
          v-model="inputValue"
          :class="['form-select', { 'is-invalid': inputError }]"
          :required="required"
          :name="name"
          :placeholder="placeholder"
          :disabled="isDisabled"
          list="list"
        />
        <slot />
      </template>
      <template v-else>
        <select
          :id="id"
          v-model="inputValue"
          :class="['form-select', { 'is-invalid': inputError }]"
          :name="name"
          :required="required"
          :placeholder="placeholder"
          :disabled="isDisabled"
        >
          <slot />
        </select>
      </template>
      <label v-if="label" :for="id">
        {{ label }} <sup v-if="required" class="text-danger">*</sup>
      </label>
    </div>
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
