<script lang="ts" setup>
import { computed } from 'vue';
import FieldConditionItem from './FieldConditionItem.vue';
import { Condition } from '@component-library/gather';
import Field from '@component-library/classes/Field';

const props = defineProps<{
  modelValue: Condition[];
  fields: Field[];
  isDefault: boolean;
  groups: Field['options']['groups'];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: Condition[]): void;
  (event: 'removeCondition', index: number): void;
}>();

const conditions = computed({
  get() {
    return props.modelValue;
  },
  set(updated) {
    emit('update:modelValue', updated);
  },
});

function updateCondition({ condition, index }) {
  const conditions = [...props.modelValue];
  conditions[index] = condition;
  emit('update:modelValue', conditions);
}
</script>

<template>
  <div class="d-flex flex-column">
    <template v-for="(condition, conditionIndex) in conditions">
      <FieldConditionItem
        :condition="condition"
        :index="conditionIndex"
        :fields="fields"
        :isDefault="isDefault"
        :groups="groups"
        @update="updateCondition"
        @removeCondition="$emit('removeCondition', conditionIndex)"
      />
      <hr v-if="conditionIndex !== conditions.length - 1" class="my-2" />
    </template>
  </div>
</template>
