<script lang="ts" setup>
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    placeholder?: string;
    name?: string;
    errorBag?: Record<string, string[] | string>;
    modelValue?: string | number | null;
    value?: string | number | null;
    required?: boolean;
    autocomplete?: string;
    autofocus?: boolean;
    invalid?: boolean;
    disabled?: boolean;
    isDisabled?: boolean;
    isLarge?: boolean;
    isSmall?: boolean;
    min?: number | string;
    max?: number | string;
    step?: number;
  }>(),
  {
    errorBag: () => ({}),
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null | undefined): void;
  (event: 'input', value: string | number | null | undefined): void;
  (event: 'change', value: string | number | null | undefined): void;
  (event: 'enter'): void;
}>();

const id = ref('');

onBeforeMount(updateId);

const inputError = computed(() => {
  if (!props.name || !(props.name in props.errorBag)) {
    return null;
  }
  return Array.isArray(props.errorBag[props.name])
    ? props.errorBag[props.name][0]
    : props.errorBag[props.name];
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}
</script>

<template>
  <div>
    <label v-if="label" class="form-label" :for="id">
      {{ label }} <sup v-if="required" class="text-danger">*</sup>
    </label>
    <input
      :id="id"
      v-model="inputValue"
      class="form-range"
      type="range"
      :class="{
        'is-invalid': invalid || inputError,
        'form-range-lg': isLarge,
        'form-range-sm': isSmall,
      }"
      :name="name"
      :required="required"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :disabled="disabled"
      :min="min"
      :max="max"
      :step="step"
      @change="$emit('change', inputValue)"
      @keyup.enter="$emit('enter')"
    />
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
