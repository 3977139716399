<script lang="ts" setup>
import AlertBox from '../../components/AlertBox.vue';
import Modal from '../../components/Modal.vue';
import Spinner from '../../components/Spinner.vue';
import useAuth from '../../composables/useAuth';
import { Item } from '../../gather';
import { useProjectStore } from '../../store/project';
import { useToastStore } from '../../store/toasts';
import { onBeforeUnmount, ref, watch } from 'vue';
import {
  loadTabLogItem as _loadTabLogItem,
  loadTabLogItem,
  remapData,
  sendPreviewRequest,
} from '.';
import TabLogsProjectLinker from './TabLogsProjectLinker.vue';
import Button from '../../components/Button.vue';

const props = defineProps<{
  item: Partial<Item>;
  hasChanges: boolean;
}>();
const emit = defineEmits<{
  (event: 'close'): void;
}>();
const previewUrl = ref<string>();
const isLoading = ref(false);
const authUser = useAuth().user();
const retryTimeout = ref<NodeJS.Timeout | null>(null);
const retries = ref(0);
const project = useProjectStore().project!;
if (!authUser || !project) {
  throw new Error('User or project not found');
}
if (!props.item.id) {
  throw new Error('Item ID not found');
}

watch(
  () => props.hasChanges && project.tab_logs_project_number,
  (hasChanges) => {
    if (hasChanges || !project.tab_logs_project_number) {
      return;
    }

    loadPreview();
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  if (retryTimeout.value) {
    clearTimeout(retryTimeout.value);
  }
});

function exportInNewTab() {
  if (!previewUrl.value) {
    throw new Error('No preview available');
  }
  window.open(previewUrl.value + '?print=pdf', '_blank');
  emit('close');
}

async function loadPreview() {
  isLoading.value = true;
  try {
    if (!props.item?.id) {
      throw new Error('Item not found');
    }
    const itemDetails = await loadTabLogItem(project.project_id, props.item.id);
    const remappedData = remapData(authUser, project, itemDetails);
    const r = await sendPreviewRequest(
      project.project_id,
      props.item.id,
      remappedData
    );

    if (!r.preview_url && r.error === null) {
      if (retryTimeout.value) {
        clearTimeout(retryTimeout.value);
      }
      retries.value++;
      if (retries.value > 5) {
        useToastStore().error('Preview not ready yet. Please try again later.');
        isLoading.value = false;
        return;
      }
      retryTimeout.value = setTimeout(() => {
        retryTimeout.value = null;
        loadPreview();
      }, 1000);
      return;
    }
    isLoading.value = false;
    retries.value = 0;

    if (!r.preview_url) {
      if (r.error !== null) {
        useToastStore().error(r.error);
        return;
      }
      useToastStore().error('Preview not ready yet. Please try again later.');
      return;
    }
    previewUrl.value = r.preview_url;
  } catch (error) {
    isLoading.value = false;
    useToastStore().unexpected(error);
    throw error;
  }
}
</script>

<template>
  <Modal :full="!!previewUrl" :fullHeight="!!previewUrl" @close="emit('close')">
    <template #header> {{ item.custom_title }} TabLogs Preview </template>

    <div class="h-100">
      <div v-if="!previewUrl && hasChanges">
        <AlertBox>
          You must save your changes before you can preview the TabLogs.
        </AlertBox>
      </div>
      <div v-else-if="!project.tab_logs_project_number">
        <TabLogsProjectLinker :project="project" />
      </div>
      <Spinner v-else-if="isLoading" />
      <AlertBox v-else-if="!previewUrl">
        No preview available. Please provide more information and try again.
      </AlertBox>
      <iframe v-else :src="previewUrl + '?button=0'" class="w-100 h-100" />
    </div>
    <template #footer>
      <Button v-if="previewUrl" color="primary" @click="exportInNewTab"
        >Export</Button
      >
      <Button @click="emit('close')">Close</Button>
    </template>
  </Modal>
</template>
