<script lang="ts" setup>
import {
  ref,
  watch,
  nextTick,
  onBeforeUnmount,
  computed,
  onMounted,
} from 'vue';
import { createPopper } from '@popperjs/core';

const props = defineProps({
  toRight: Boolean,
  onTop: Boolean,
  onRight: Boolean,
  onLeft: Boolean,
  customWidth: {
    type: String,
    default: '250px',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  forceOpen: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: 'px-3',
  },
  blockClose: {
    type: Boolean,
    default: false,
  },
});

const triggerButtonRef = ref<HTMLElement>();
const dropdownMenuRef = ref<HTMLElement>();
const toggled = ref(false);
const shouldBlockClose = ref(false);

let popperInstance: null | any = null;

const emit = defineEmits(['toggled']);

watch(
  () => props.blockClose,
  (val) => {
    setTimeout(() => {
      shouldBlockClose.value = val;
    }, 300);
  }
);

const placement = computed(() => {
  if (props.onTop) {
    return props.onRight || props.toRight ? 'top-end' : 'top-start';
  } else {
    return props.onRight || props.toRight ? 'bottom-end' : 'bottom-start';
  }
});

const createPopperInstance = () => {
  if (triggerButtonRef.value && dropdownMenuRef.value) {
    popperInstance = createPopper(
      triggerButtonRef.value,
      dropdownMenuRef.value,
      {
        placement: placement.value,
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
      }
    );
  }
};

const destroyPopperInstance = () => {
  if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
};

watch(
  () => props.forceOpen,
  async (val) => {
    if (!val) return;
    toggled.value = true;
    emit('toggled', true);
    await nextTick();
    createPopperInstance();
  }
);

const toggle = async () => {
  toggled.value = !toggled.value;
  emit('toggled', toggled.value);

  if (toggled.value) {
    await nextTick();
    createPopperInstance();
  } else {
    destroyPopperInstance();
  }
};

function close(event: MouseEvent | undefined) {
  if (!toggled.value || shouldBlockClose.value) return;
  if (
    event?.target &&
    (triggerButtonRef.value?.contains(event.target as Node) ||
      dropdownMenuRef.value?.contains(event.target as Node))
  ) {
    return;
  }
  toggle();
}

onMounted(() => {
  document.addEventListener('click', close);
});

defineExpose({ toggle, toggled, close });

onBeforeUnmount(() => {
  destroyPopperInstance();
  document.removeEventListener('click', close);
});
</script>

<template>
  <div class="dropdown">
    <div ref="triggerButtonRef" class="h-100 w-100">
      <slot name="button" :toggle="toggle" :toggled="toggled" :close="close" />
    </div>

    <Teleport to="body">
      <div
        v-if="toggled"
        ref="dropdownMenuRef"
        class="dropdown-menu mt-1"
        :class="[
          padding,
          {
            'd-block': toggled,
          },
        ]"
        :style="{
          width: customWidth === '100%' ? 'unset' : customWidth,
          ...customStyle,
        }"
      >
        <slot name="items" :toggled="toggled" :close="toggle" />
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
.dropdown-menu {
  z-index: 20003;
}
@media only screen and (max-width: 576px) {
  .dropdown-menu {
    width: 100% !important;
    margin: 0;
  }
}
</style>
