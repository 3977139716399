import { InputValue } from '@component-library/gather';
import {
  NewItemInputValue,
  OfflineProject,
  OfflineSample,
} from '@component-library/offline-data';
import { captureException } from '@component-library/sentry';
import axios from 'axios';
import { sanitizeFileName } from '../helpers/general';
import useAuth from '@component-library/composables/useAuth';

// Records the change of a sample id from a string value to a number value.
// Used for restoration the linking relationship between items.
export type SampleIdChange = {
  stringSampleId: string;
  numberSampleId: number;
};

export default function useSampleUploadManager(project: OfflineProject) {
  const updateOnlineSample = async (
    sample: OfflineSample
  ): Promise<SampleIdChange | undefined> => {
    const offlineSample = project.samples.find(
      (item) => item.id === sample.id
    )!;

    if (offlineSample !== sample) {
      // The offline item has been removed from the cache.
      throw `The offline item is missing.`;
    }

    const uploadedAssetValues: (InputValue | NewItemInputValue)[] = [];

    for (const item of offlineSample.values ?? []) {
      if (item.value instanceof Blob) {
        try {
          const src = await _uploadAsset(item.value, item);
          uploadedAssetValues.push({
            ...item,
            value: src,
          });
        } catch (e) {
          captureException('Failed to upload offline single asset', {
            extra: {
              e,
              item,
            },
          });

          throw 'Failed to upload single asset.';
        }
      } else {
        if (Array.isArray(item.value) && item.value.length) {
          const successfulAssetUploads: string[] = [];

          for (let i = 0; i < item.value.length; i++) {
            const asset = item.value[i];
            const { blob, name } = asset;
            if (blob instanceof Blob) {
              try {
                const src = await _uploadAsset(blob, item, name);
                successfulAssetUploads.push(src);
              } catch (e) {
                captureException('Failed to upload offline multi asset', {
                  extra: {
                    e,
                    item,
                  },
                });

                throw 'Failed to upload multi asset.';
              }
            }
          }

          uploadedAssetValues.push({
            ...item,
            value: item.value.map((asset, index) => ({
              ...asset,
              src: successfulAssetUploads[index],
            })),
          });
        } else {
          uploadedAssetValues.push(item);
        }
      }
    }

    const sampleData: SampleData = {
      ...offlineSample,
      sample_id: offlineSample.id,
      offline_user_id: null,
    };

    // The item was created or duplicated in the offline mode.
    const { sample_id } = sampleData;
    const isNewOfflineSample = sample_id && typeof sample_id !== 'number';
    if (isNewOfflineSample) {
      delete sampleData.sample_id;
    }

    try {
      const { sample } = await saveSampleAndValues(
        sampleData,
        uploadedAssetValues
      );
      return isNewOfflineSample
        ? { stringSampleId: sample_id, numberSampleId: sample.id }
        : undefined;
    } catch (e) {
      captureException('Failed to upload item & values', {
        extra: {
          e,
        },
      });

      throw 'Failed to update item & values';
    }
  };

  type SampleData = {
    template_tab_id: number | null;
    sample_id?: string | number;
    custom_title: string;
    latitude: number;
    longitude: number;
    icon_rotation?: number | null;
    icon_opacity?: number | null;
    icon_opacity_override?: number | null;
    area_geojson: any;
    sub_folder?: null | any[];
    offline_user_id: number | null;
  };

  const saveSampleAndValues = async (
    sampleData: SampleData,
    inputValues: (InputValue | NewItemInputValue)[]
  ) => {
    const {
      template_tab_id,
      sample_id: sampleId,
      custom_title,
      latitude,
      longitude,
      icon_rotation,
      icon_opacity,
      icon_opacity_override,
      area_geojson,
      sub_folder,
    } = sampleData;

    const isNonSpatial = _checkIsNonSpatial(sampleData);

    try {
      const {
        data: {
          sample,
          input_values: _input_values,
          area_figure_layer,
          samples_count,
        },
      } = await axios.post('/api/project/sample/update', {
        template_tab_id,
        sample_id: sampleId,
        custom_title: custom_title || 'Untitled Sample',
        latitude: isNonSpatial ? null : latitude,
        longitude: isNonSpatial ? null : longitude,
        icon_rotation,
        icon_opacity,
        icon_opacity_override,
        area_geojson,
        input_values: inputValues,
        sub_folder,
        project_id: project.project_id,
      });

      return {
        sample,
        input_values: _input_values,
        area_figure_layer,
        samples_count,
      };
    } catch (e) {
      captureException(e);
      throw e;
    }
  };

  const _uploadAsset = async (
    blob: Blob,
    inputValue: InputValue | NewItemInputValue,
    name: string | null = null
  ) => {
    const data = new FormData();

    let isJSON = false;
    // ! Only a check, will fail if not JSON but needs to continue
    try {
      if (JSON.parse(inputValue.value2 as string)) {
        isJSON = true;
      }
    } catch {}

    name = sanitizeFileName(
      isJSON ? 'drawing.png' : name || (inputValue.value2 as string)
    ) as string;

    console.error('testing');
    console.error(blob);
    console.error('size: ' + blob.size);

    data.append(
      'file',
      new File([blob], name, {
        type: blob.type,
      })
    );
    data.append('project_id', project.project_id.toString());

    const auth = useAuth();

    const response = await fetch(`/api/project/asset`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'X-CSRF-TOKEN': auth.getXsrfToken() || '',
      },
    });
    const result = await response.json();

    return result.src;
  };

  const _checkIsNonSpatial = (sample: SampleData) => {
    return !sample.area_geojson && !sample.longitude && !sample.latitude;
  };

  const bringSampleOnline = async (id: number | string) => {
    await axios.patch(`/api/offline/upload/${id}`);
  };

  const restoreSampleLinks = async (sampleIdChanges: SampleIdChange[]) => {
    await axios.post(`/api/offline/upload/restore-sample-links`, {
      sample_id_changes: sampleIdChanges,
    });
  };

  return {
    updateOnlineSample,
    saveSampleAndValues,
    bringSampleOnline,
    restoreSampleLinks,
  };
}
