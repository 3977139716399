<script lang="ts" setup>
import { useProjectStore } from '@component-library/store/project';
import { useRouter } from 'vue-router';

defineProps<{
  /** Vue-router route names */
  routes: string[];
}>();
const router = useRouter();
const projectStore = useProjectStore();
const project = projectStore.project;

function getRouteTitleFromName(routeName: string) {
  const routes = router.getRoutes();
  return routes.find((r) => r.name == routeName)?.meta?.title || routeName;
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between">
    <h6 class="text-muted d-flex align-items-center mb-0">
      <RouterLink
        v-if="project"
        class="mx-3"
        :to="`/${project.project_id}/map`"
        style="text-decoration: none"
      >
        <span class="text-black">{{ project.project_name }}</span>
        <div class="text-muted small" style="text-decoration: none">
          {{ project.project_number }}
        </div>
      </RouterLink>
      <div
        v-for="(route, routeIndex) in routes"
        :key="routeIndex"
        class="d-flex align-items-center"
      >
        <i class="fal fa-chevron-right mx-3" style="font-size: 10px" />
        <div v-if="routeIndex == routes.length - 1">
          {{ getRouteTitleFromName(route) }}
        </div>
        <RouterLink v-else :to="{ name: route }">
          {{ getRouteTitleFromName(route) }}
        </RouterLink>
      </div>
    </h6>
    <slot />
  </div>
</template>
