<script setup>
import {
  INPUT_TYPE_VALUE,
  VALUE_TYPE_DATE,
  VALUE_TYPE_DATE_TIME,
  VALUE_TYPE_EMPTY_TEXT,
  VALUE_TYPE_NUMBER,
  VALUE_TYPE_OPTIONS,
  VALUE_TYPE_TEXT,
  VALUE_TYPE_TIME,
  VALUE_TYPE_YEAR,
  VALUE_TYPE_YEAR_MONTH,
  VALUE_TYPE_YES_NO,
} from '@component-library/business-model/expression';
import makeId from '@component-library/local-id.mjs';
import { computed, ref, watch } from 'vue';
import CheckboxValueContentInput from './value-content-inputs/Checkbox.vue';
import DateValueContentInput from './value-content-inputs/Date.vue';
import DateTimeValueContentInput from './value-content-inputs/DateTime.vue';
import NumberValueContentInput from './value-content-inputs/Number.vue';
import TextValueContentInput from './value-content-inputs/Text.vue';
import TimeValueContentInput from './value-content-inputs/Time.vue';
import YearValueContentInput from './value-content-inputs/Year.vue';
import YearMonthValueContentInput from './value-content-inputs/YearMonth.vue';

const emit = defineEmits(['add']);
const id = makeId();

const value = ref({
  type: VALUE_TYPE_TEXT,
  content: '',
});
const valueContentType = computed(() => {
  switch (value.value.type) {
    case VALUE_TYPE_TEXT:
    case VALUE_TYPE_EMPTY_TEXT:
      return TextValueContentInput;
    case VALUE_TYPE_NUMBER:
      return NumberValueContentInput;
    case VALUE_TYPE_DATE:
      return DateValueContentInput;
    case VALUE_TYPE_YEAR_MONTH:
      return YearMonthValueContentInput;
    case VALUE_TYPE_YEAR:
      return YearValueContentInput;
    case VALUE_TYPE_DATE_TIME:
      return DateTimeValueContentInput;
    case VALUE_TYPE_TIME:
      return TimeValueContentInput;
    case VALUE_TYPE_YES_NO:
      return CheckboxValueContentInput;
    default:
      return TextValueContentInput;
  }
});

const isEmptyText = computed(() => value.value.type === VALUE_TYPE_EMPTY_TEXT);

watch(
  () => value.value.type,
  () => {
    value.value.content = '';
  }
);

function handleAdd() {
  emit('add', {
    type: INPUT_TYPE_VALUE,
    value: value.value.content,
    valueType: value.value.type,
  });
}
</script>

<template>
  <div class="d-flex flex-column">
    <div class="mb-3">
      <label class="form-label" :for="id">Type</label>
      <select :id="id" v-model.number="value.type" class="form-control">
        <option
          v-for="item in Object.keys(VALUE_TYPE_OPTIONS)"
          :key="`valueType-${item}`"
          :value="item"
        >
          {{ VALUE_TYPE_OPTIONS[item].text }}
        </option>
      </select>
    </div>

    <div v-if="!isEmptyText" class="mb-3">
      <label class="form-label">Content</label>
      <component :is="valueContentType" v-model="value.content" />
    </div>

    <div class="d-flex">
      <button
        class="btn btn-outline-dark btn-sm ms-1 flex-grow-1"
        :disabled="!value.content && !isEmptyText"
        @click="handleAdd"
      >
        <i class="fas fa-plus" />
      </button>
    </div>
  </div>
</template>
