import { ScenarioSet } from './enviro';
import { App, DrawingType, ProjectPhase, InputValue } from './gather';
import { Project } from './project';
import { User } from './user';

// move to @component-library?
import type {
  AppStylingRule,
  Chemical,
  ChemicalResult,
  Exceedance,
  Figure,
  FigureStylingRule,
  Id,
  LayerModel,
  Sample,
  Scenario,
  ScenarioStyle,
  SubFolder,
} from '../maps/lib/olbm/types';

/** Local sample_id are strings */
export type NewItemInputValue = Omit<InputValue, 'sample_id'> & { sample_id: number | string };

export type OfflineSample = {
  id: number | string;
  custom_title: string;
  lab_title?: string;
  project_figure_layer_id: number | null;
  offline_user_id: number | null; // The offline_user_id of samples created or duplicated in the offline mode should be null.
  values: (InputValue | NewItemInputValue)[];
  area_figure_layer: any;
  area_geojson: any;
  template_tab_id: number | null;
  longitude: number;
  latitude: number;
  sub_folder?: null | any[];
  label_position: any;
  is_deleted?: boolean;
  is_edited_offline?: boolean; // set on save of sample offline
  is_created_offline?: boolean; // set on save of sample offline
  is_invalidated?: boolean;
  points_of_interest?: any[];
  input_values_for_linking?: (InputValue | NewItemInputValue)[];
};

export type OfflineTemplateTab = App & {
  drawing_type: DrawingType;
  previous_app_requirement: number | null;
  is_read_only: boolean;
};

export type OfflineProject = Project &
  OfflineFigureData & {
    template_tabs: OfflineTemplateTab[];
    phases: ProjectPhase[];
    samples: OfflineSample[];
    loaded_areas: LoadedArea[];
    offline_min_zoom?: number;
    offline_requests: ProjectOfflineRequest[];
    company_users: User[];
  };

// The polySmples is replaced by the lopSampleByLayerId for better code readibility.
export type DeprecatedOfflineProjectV1 = Omit<
  OfflineProject,
  'lopSampleByLayerId'
> & {
  polySamples: Record<Id, Sample>;
};

export function checkIsDeprecatedOfflineProjectV1(
  offlineProject: OfflineProject | DeprecatedOfflineProjectV1
): offlineProject is DeprecatedOfflineProjectV1 {
  return 'polySamples' in offlineProject;
}

export type OfflineFigureData = {
  figures: Figure[];
  figureStylingRules: FigureStylingRule[];
  appStylingRules: AppStylingRule[];
  selectedFigureId: number;
  layerModels: LayerModel[];
  // Check FigureController.loadLopSamples to get the definition of LOP.
  lopSampleByLayerId: Record<Id, Sample>;
  subFolders: SubFolder[];
  scenarios?: Scenario[];
  scenarioStyles?: ScenarioStyle[];
  scenarioSet?: ScenarioSet;
  chemicals?: Chemical[];
  exceedances?: Exceedance[];
  chemicalResults?: ChemicalResult[];
};

export type LoadedArea = {
  bounds: string;
  zoom: number;
};

export type OfflineUser = User & {
  offline_samples_count: number;
  is_invalidated?: boolean;
};

export type ProjectOfflineManagementRequestData = {
  user_id: number;
  figure_id: number | null;
  basemap: number;
  radius: number;
  app_ids: number[];
  sample_ids: number[];
  device: string | null;
};

export type ProjectOfflineRequestStatus =
  | 'ready_to_sync'
  | 'device_received'
  | 'is_syncing'
  | 'synced'
  | 'failed_sync'
  | 'has_published'
  | 'invalidated';

export type ProjectOfflineRequest = {
  id: number;
  user_id: number;
  project_id: number;
  radius: number;
  basemap: number;
  figure_id: number;
  status: ProjectOfflineRequestStatus;
  data: ProjectOfflineRequestData[];
  device: string | null;
  samples_count?: number;
  apps_count?: number;
  outdated_apps_count?: number;
  updated_at: string;
};

export type ProjectOfflineRequestData = {
  app_id: number | null;
  sample_id: number | null;
};

export const formatOfflineRequestStatus = (
  status: ProjectOfflineRequestStatus
) => {
  switch (status) {
    case 'ready_to_sync':
      return 'Waiting for device to download';
    case 'device_received':
      return 'Device received request (awaiting download)';
    case 'is_syncing':
      return 'Syncing to users device';
    case 'synced':
      return 'Synced to users device (ready to use offline)';
    case 'failed_sync':
      return 'Failed sync';
    case 'has_published':
      return 'Published';
    case 'invalidated':
      return 'Invalidated';
    default:
      return 'Unknown';
  }
};

export const getOfflineRequestStatusColor = (
  status: ProjectOfflineRequestStatus | null
) => {
  switch (status) {
    case 'synced':
      return 'bg-success';
    case 'failed_sync':
      return 'bg-danger';
    case 'invalidated':
      return 'bg-dark';
  }

  return 'bg-secondary';
};

export type ProjectOfflineRequestWithUser = ProjectOfflineRequest & {
  user: OfflineUser;
};

export const getDefaultOfflineRequestData = (
  userId: number
): ProjectOfflineManagementRequestData => {
  return {
    user_id: userId,
    figure_id: null,
    basemap: 0,
    radius: 100,
    app_ids: [],
    sample_ids: [],
    device: null,
  };
};

export type ProjectOfflineQuickStatistics = {
  apps_count: number;
};

export enum SWBackEndMessageType {
  OFFLINE_DOWNLOAD = 'offline-download',
  OFFLINE_ADMIN_DATA_SERVICE_TYPE = 'offline-admin-data',
}

export enum SWFrontEndMessageType {
  DOWNLOAD_IN_PROGRESS = 'download-in-progress',
  DOWNLOAD_COMPLETE = 'download-complete',
  DOWNLOAD_FAILED = 'download-failed',
  NOTIFICATION = 'notification',
}
